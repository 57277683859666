<template>

<!-- Frequently Asked Questions Text Section -->
<div class="container-fluid faq-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 text-center faq-content-div">
                <h2 class="text-uppercase">Frequently Asked Questions</h2>
                
                <div class="row">
                    <div class="col-md-6 faq-text-div">
                        <div class="row">
                            <div class="col-lg-1 col-md-2 faq-img-div p-0">
                                <div class="number-1-div">1</div>
                                <img src="../assets/images/faqline-img.png" alt="">
                            </div>
                            <div class="col-md-9">
                                <h5>What can we help you with?</h5>
                                <p>We Will Become Your Go-To Team For Any Kind Of Services You Can Think Of For IT. </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 faq-text-div">
                        <div class="row">
                            <div class="col-lg-1 col-md-2 faq-img-div p-0">
                                <div class="number-2-div">2</div>
                                <img src="../assets/images/faqline-img.png" alt="">
                            </div>
                            <div class="col-md-9">
                                <h5>What kind of businesses we can help to grow online?</h5>
                                <p>So Far We Have Taken Care Of People Who Needed Projects For Business, Sports, Gaming, Social Networking, Education, Nonprofit Organizations, Restaurants, Agencies, And Any Other Online Expedition That Our Clients Needed From Us. </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 faq-text-div">
                        <div class="row">
                            <div class="col-lg-1 col-md-2 faq-img-div p-0">
                                <div class="number-3-div">3</div>
                                <img src="../assets/images/faqline-img.png" alt="">
                            </div>
                            <div class="col-md-9">
                                <h5>How can you start getting our services?</h5>
                                <p>Well, You Just Email Us Through The Contact Form And We Will Take Care Of The Rest. </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 faq-text-div">
                        <div class="row">
                            <div class="col-lg-1 col-md-2 faq-img-div p-0">
                                <div class="number-4-div">4</div>
                                <img src="../assets/images/faqline-img.png" alt="">
                            </div>
                            <div class="col-md-9">
                                <h5>What is our expertise?</h5>
                                <p>We Are Experts In Providing Websites, Designs, Branding, Digital Marketing, UI/UX Designing, Logos, And All That You Need For Your Online Appearance.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 faq-text-div">
                        <div class="row">
                            <div class="col-lg-1 col-md-2 faq-img-div p-0">
                                <div class="number-5-div">5</div>
                            </div>
                            <div class="col-md-9">
                                <h5>Which technologies do we choose for your projects?</h5>
                                <p>Depending On The Scale Of The Project We Have A Variety Of Tech We Can Consider Which Varies From WordPress To Laravel For Simple To Most Complex Projects You Can Come Across </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 faq-text-div">
                        <div class="row">
                            <div class="col-lg-1 col-md-2 faq-img-div p-0">
                                <div class="number-6-div">6</div>
                            </div>
                            <div class="col-md-9">
                                <h5>The duration of the project is decided by who?</h5>
                                <p>If You Got A Deadline In Mind We Make Sure To Always Deliver. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



</template>

<script>
export default {
 name:'FaqComp'   
}
</script>
