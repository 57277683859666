

<template>

<NavbarHeader/>

<!-- <ParacticeTime/> -->

<router-view />


<Footer />
  
</template>

<script>

import NavbarHeader from "./components/NavbarHeader.vue";
import Footer from "./components/Footer.vue";

export default {
  name: 'App',
  components:{
    NavbarHeader,
    Footer,
    // ParacticeTime,
  }
}
</script>
<style></style>
