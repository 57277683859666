
import { createApp } from 'vue'
import App from './App.vue'
import router from './router/routes'
import AOS from 'aos'
import jQuery from 'jquery'
import $ from 'jquery'
import '../node_modules/aos/dist/aos.css'
import '../node_modules/aos/dist/aos.js'
import '../node_modules/jquery/dist/jquery.min.js'

import "../node_modules/bootstrap/dist/js/bootstrap.js"
import "../node_modules/bootstrap/dist/css/bootstrap.css"

import '@fortawesome/fontawesome-free/css/all.css'

import "../src/assets/css/style.css"






const app = createApp(App);
app.use(router)
app.use(jQuery)
app.use($)
app.use(AOS)


app.mount('#app')

AOS.init({
    duration: 700,
    // easing: "ease-in-out",
    // once: false,
    mirror: true,
  });
