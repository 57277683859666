<template>


<!-- Join Our Team SECTION -->
<div class="container-fluid">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="row justify-content-center">
                    <div class="col-md-5 text-center join-our-team-content-text" data-aos="fade-up" data-aos-delay="200">
                        <h2><b>JOIN OUR TEAM</b></h2>
                        
                        <p>Home <span> / Career</span></p>
                    </div>
                    <div class="col-md-7 text-center join-our-team-content-img-div">
                        <img src="../assets/images/career-front-content-img.png" alt="" data-aos="zoom-in" data-aos-delay="300">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Why Join Us Section -->
<div class="container-fluid why-join-us-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 text-center" data-aos="fade-up" data-aos-delay="200">
                <h1>WHY JOIN US</h1>
            </div>
        </div>
        <div class="row justify-content-center why-join-us-catagories">
            <div class="col-md-4" data-aos="fade-up" data-aos-delay="200">
                <h4>Fast Growing Company</h4>
                <p>
                    Having Experts That Are Pros We Excelled In Our Game In A Short Span Of Time And So Will You.
                </p>
            </div>
            <div class="col-md-4" data-aos="fade-up" data-aos-delay="300">
                <h4>Great Colleagues</h4>
                <p>
                    We Appreciate A Friendly Environment Where Everyone Can Work In Peace Because We Help Each Other Grow.
                </p>
            </div>
            <div class="col-md-4" data-aos="fade-up" data-aos-delay="400">
                <h4>Latest Technology</h4>
                <p>
                    Whichever Project We Work On We Make Sure To Choose Latest Skills Because That Is What IT Is All About.
                </p>
            </div>
        </div>
    </div>
</div>


<!-- Job Application Section -->
<div class="container-fluid job-application-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6 text-center" data-aos="fade-up" data-aos-delay="300">
                <h2> JOB APPLICATION FORM </h2>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-12 job-application-form p-4" data-aos="fade-up" data-aos-delay="200">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com">
                            <label for="floatingInput">Full Name</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-floating mb-3">
                            <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
                            <label for="floatingInput">E-mail</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-floating mb-3">
                            <input type="number" class="form-control" id="floatingInput" placeholder="name@example.com">
                            <label for="floatingInput">Phone</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com">
                            <label for="floatingInput">Position</label>
                        </div>
                    </div>
                </div> 
                
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-floating mb-3">
                            <textarea class="form-control" id="floatingInput" placeholder="name@example.com"></textarea>
                            <label for="floatingInput">Adress</label>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com">
                            <label for="floatingInput">State</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com">
                            <label for="floatingInput">City</label>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-floating mb-3 upload-cv-div text-center">
                            <input @change="imagePreview" id="floatingInput" type="file" class="form-control cv-file">
                            <!-- <img v-if="url" :src="url" /> -->
                            <!-- <pdf v-if="url" :src="url" /> -->
                            
                            <!-- <img src="../assets/images/file-type.png" alt=""> -->
                            <!-- <textarea class="form-control" id="floatingInput" placeholder="name@example.com"></textarea> -->
                            <label for="floatingInput text-center">
                                <p>    
                                    Upload your resume
                                </p>
                                
                            </label>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-floating mb-3">
                            <textarea class="form-control" id="floatingInput" placeholder="name@example.com"></textarea>
                            <label for="floatingInput">Additional infomartion</label>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-end">
                    <div class="col-md-4">
                        <button class="apply-now-btn">Apply Now</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>


</template>

<script>
// import{ref} from 'vue';
export default {
    name: 'CareerComp',


// setup(){
// const url = ref();
//     const imagePreview = (e)=>{
//         const file = e.target.files[0];
//         url.value = URL.createObjectURL(file);
//     }
//     return{
//         imagePreview,
//         url
//     }
// }
}
</script>
<style>
</style>