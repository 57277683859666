<template>


    <!-- Our Portfolio == Portfolio Front Main Content  SECTION -->
    <div class="container-fluid">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-12">
                    <div class="row justify-content-center">
                        <div class="col-md-5 text-center portfolio-content-text" data-aos="fade-down" data-aos-delay="200">
                            <h2><b>OUR PORTFOLIO</b></h2>
                            <p>Home <span> / Porfolio</span></p>
                        </div>
                        <div class="col-md-7 text-center portfolio-content-img-div">
                            <img src="../assets/images/portfolio-front-content-img.png" alt="" data-aos="zoom-in" data-aos-delay="300">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    
    <!-- Our Portfolio Link List -->
    <div class="container-fluid">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-5 text-center p-4" data-aos="fade-up" data-aos-delay="300">
                    <h1>OUR PORTFOLIO</h1>
                </div>
            </div> 
        </div>
    </div>
    
    
    <!-- Projects list  Section -->
    <div class="container-fluid project-list-section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-12">
                    <div class="row justify-content-center">
                        <div class="col-md-8 nav-tab-div">
                            
                            <ul class="nav nav-tabs our-recent-project-ul" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                  <button class="active portfolio-catagory-tab-btn" id="home-tab" data-bs-toggle="tab" data-bs-target="#all" type="button" role="tab" aria-controls="home" aria-selected="true">All</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                  <button class="portfolio-catagory-tab-btn" id="profile-tab" data-bs-toggle="tab" data-bs-target="#website" type="button" role="tab" aria-controls="website" aria-selected="false">Website</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="portfolio-catagory-tab-btn" id="contact-tab" data-bs-toggle="tab" data-bs-target="#mobile" type="button" role="tab" aria-controls="mobile" aria-selected="false">Mobile</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="portfolio-catagory-tab-btn" id="contact-tab" data-bs-toggle="tab" data-bs-target="#graphic" type="button" role="tab" aria-controls="graphic" aria-selected="false">Graphic</button>
                                </li>
                              </ul>
                        </div>
                      </div>
                      
                      <div class="tab-content" id="myTabContent">
                              
                        <div class="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="home-tab">
    
                            <div class="row justify-content-center">
                                <div class="col-md-12 text-center m-5" data-aos="fade-up" data-aos-delay="600">
                                    <h2> <b> OUR RECENT PROJECTS </b> </h2>
                                </div>
                            </div>
    
    
                            <div class="col-md-12">
                                <div class="row">
    
                                    <!-- Blue Sky -->
                                    <div class="col-md-4 h-100">
                                        
                                        <div class="col-md-12 website-img-div">                                    
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal12" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/Blue-Sky-scaled.jpg" alt="">
                                            </a>
                                            <div class="modal fade" id="exampleModal12" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
    
                                                    <div class="modal-body">
    
                                                        <div id="carouselExampleIndicatorsOne" class="carousel slide" data-bs-ride="carousel">
    
    
                                                            <div class="carousel-indicators">
                                                                <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsOne" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                    <img src="../assets/images/Blue-Sky-2-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsOne" data-bs-slide-to="1" aria-label="Slide 2">
                                                                    <img src="../assets/images/Blue-Sky-3-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsOne" data-bs-slide-to="2" aria-label="Slide 3">
                                                                    <img src="../assets/images/Blue-Sky-4-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsOne" data-bs-slide-to="3" aria-label="Slide 3">
                                                                    <img src="../assets/images/Blue-Sky-5-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                            </div>
    
    
                                                            <div class="carousel-inner">
                                                                <div class="carousel-item active">
                                                                    <img src="../assets/images/Blue-Sky-2-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Blue-Sky-3-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Blue-Sky-4-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Blue-Sky-5-scaled.jpg" alt="">
                                                                </div>
                                                            </div>
    
    
                                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsOne" data-bs-slide="prev">
                                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Previous</span>
                                                            </button>
                                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsOne" data-bs-slide="next">
                                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Next</span>
                                                            </button>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            </div>
                                        </div>
    
                                        <div class="col-md-12 website-info-div">
                                            <h5>Blue Sky</h5>
                                        </div>
                                        
    
                                    </div>
    
                                    <!-- Cleaning Automaters -->
                                    <div class="col-md-4 h-100">
                                        <div class="col-md-12 website-img-div">
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal13" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/cleaning-automaters-scaled.jpg" alt="">
                                            </a>
                                            
                                            <div class="modal fade" id="exampleModal13" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
    
                                                    <div class="modal-body">
    
                                                        <div id="carouselExampleIndicatorsTwo" class="carousel slide" data-bs-ride="carousel">
    
    
                                                            <div class="carousel-indicators">
                                                                <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsTwo" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                    <img src="../assets/images/cleaning-automaters-2-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwo" data-bs-slide-to="1" aria-label="Slide 2">
                                                                    <img src="../assets/images/cleaning-automaters-3-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwo" data-bs-slide-to="2" aria-label="Slide 3">
                                                                    <img src="../assets/images/cleaning-automaters-4-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwo" data-bs-slide-to="3" aria-label="Slide 3">
                                                                    <img src="../assets/images/cleaning-automaters-5-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                            </div>
    
    
                                                            <div class="carousel-inner">
                                                                <div class="carousel-item active">
                                                                    <img src="../assets/images/cleaning-automaters-2-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/cleaning-automaters-3-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/cleaning-automaters-4-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/cleaning-automaters-5-scaled.jpg" alt="">
                                                                </div>
                                                            </div>
    
    
                                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsTwo" data-bs-slide="prev">
                                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Previous</span>
                                                            </button>
                                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsTwo" data-bs-slide="next">
                                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Next</span>
                                                            </button>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            </div>
                                        </div>
    
                                        <div class="col-md-12 website-info-div">
                                            <h5>Cleaning Automaters</h5>
                                        </div>
    
                                    </div>
    
                                    <!-- G.I Party -->
                                    <div class="col-md-4 h-100">
                                        <div class="col-md-12 website-img-div">
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal14" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/G.I-Party-scaled.jpg" alt="">
                                            </a>
                                            
                                            <div class="modal fade" id="exampleModal14" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
    
                                                    <div class="modal-body">
    
                                                        <div id="carouselExampleIndicatorsThree" class="carousel slide" data-bs-ride="carousel">
    
    
                                                            <div class="carousel-indicators">
                                                                <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsThree" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                    <img src="../assets/images/G.I-Party-2-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsThree" data-bs-slide-to="1" aria-label="Slide 2">
                                                                    <img src="../assets/images/G.I-Party-3-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsThree" data-bs-slide-to="2" aria-label="Slide 3">
                                                                    <img src="../assets/images/G.I-Party-4-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsThree" data-bs-slide-to="3" aria-label="Slide 3">
                                                                    <img src="../assets/images/G.I-Party-5-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                            </div>
    
    
                                                            <div class="carousel-inner">
                                                                <div class="carousel-item active">
                                                                    <img src="../assets/images/G.I-Party-2-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/G.I-Party-3-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/G.I-Party-4-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/G.I-Party-5-scaled.jpg" alt="">
                                                                </div>
                                                            </div>
    
    
                                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsThree" data-bs-slide="prev">
                                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Previous</span>
                                                            </button>
                                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsThree" data-bs-slide="next">
                                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Next</span>
                                                            </button>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            </div>
                                        </div>
    
                                        <div class="col-md-12 website-info-div">
                                            <h5>G.I Party</h5>
                                        </div>
    
                                    </div>
                                    
                                    <!-- Mop-A-Lot -->
                                    <div class="col-md-4 h-100">
                                        <div class="col-md-12 website-img-div">
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal15" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/Mop-A-Lot-scaled.jpg" alt="">
                                            </a>
                                            
                                            <div class="modal fade" id="exampleModal15" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
    
                                                    <div class="modal-body">
    
                                                        <div id="carouselExampleIndicatorsFour" class="carousel slide" data-bs-ride="carousel">
    
    
                                                            <div class="carousel-indicators">
                                                                <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsFour" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                    <img src="../assets/images/Mop-A-Lot-2-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsFour" data-bs-slide-to="1" aria-label="Slide 2">
                                                                    <img src="../assets/images/Mop-A-Lot-3-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsFour" data-bs-slide-to="2" aria-label="Slide 3">
                                                                    <img src="../assets/images/Mop-A-Lot-4-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsFour" data-bs-slide-to="3" aria-label="Slide 3">
                                                                    <img src="../assets/images/Mop-A-Lot-5-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                            </div>
    
    
                                                            <div class="carousel-inner">
                                                                <div class="carousel-item active">
                                                                    <img src="../assets/images/Mop-A-Lot-2-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Mop-A-Lot-3-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Mop-A-Lot-4-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Mop-A-Lot-5-scaled.jpg" alt="">
                                                                </div>
                                                            </div>
    
    
                                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsFour" data-bs-slide="prev">
                                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Previous</span>
                                                            </button>
                                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsFour" data-bs-slide="next">
                                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Next</span>
                                                            </button>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            </div>
                                        </div>
    
                                        <div class="col-md-12 website-info-div">
                                            <h5>Mop-A-Lot</h5>
                                        </div>
    
                                    </div>
    
                                    <!-- Sparkle Easy -->
                                    <div class="col-md-4 h-100">
                                        <div class="col-md-12 website-img-div">
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal16" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/Sparkle-Easy-scaled.jpg" alt="">
                                            </a>
                                            
                                            <div class="modal fade" id="exampleModal16" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
    
                                                    <div class="modal-body">
    
                                                        <div id="carouselExampleIndicatorsFive" class="carousel slide" data-bs-ride="carousel">
    
    
                                                            <div class="carousel-indicators">
                                                                <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsFive" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                    <img src="../assets/images/Sparkle-Easy-2-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsFive" data-bs-slide-to="1" aria-label="Slide 2">
                                                                    <img src="../assets/images/Sparkle-Easy-3-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsFive" data-bs-slide-to="2" aria-label="Slide 3">
                                                                    <img src="../assets/images/Sparkle-Easy-4-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsFive" data-bs-slide-to="3" aria-label="Slide 3">
                                                                    <img src="../assets/images/Sparkle-Easy-5-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                            </div>
    
    
                                                            <div class="carousel-inner">
                                                                <div class="carousel-item active">
                                                                    <img src="../assets/images/Sparkle-Easy-2-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Sparkle-Easy-3-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Sparkle-Easy-4-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Sparkle-Easy-5-scaled.jpg" alt="">
                                                                </div>
                                                            </div>
    
    
                                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsFive" data-bs-slide="prev">
                                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Previous</span>
                                                            </button>
                                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsFive" data-bs-slide="next">
                                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Next</span>
                                                            </button>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            </div>
                                        </div>
    
                                        <div class="col-md-12 website-info-div">
                                            <h5>Sparkle Easy</h5>
                                        </div>
    
                                    </div>
    
                                    <!-- Paladar -->
                                    <div class="col-md-4 h-100">
                                        <div class="col-md-12 website-img-div">
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal17" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/Paladar.jpg" alt="">
                                            </a>
                                            
                                            <div class="modal fade" id="exampleModal17" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
    
                                                    <div class="modal-body">
    
                                                        <div id="carouselExampleIndicatorsSix" class="carousel slide" data-bs-ride="carousel">
    
    
                                                            <div class="carousel-indicators">
                                                                <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsSix" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                    <img src="../assets/images/Paladar.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsSix" data-bs-slide-to="1" aria-label="Slide 2">
                                                                    <img src="../assets/images/Paladar-2-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsSix" data-bs-slide-to="2" aria-label="Slide 3">
                                                                    <img src="../assets/images/Paladar-3-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsSix" data-bs-slide-to="3" aria-label="Slide 3">
                                                                    <img src="../assets/images/Paladar-4-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                            </div>
    
    
                                                            <div class="carousel-inner">
                                                                <div class="carousel-item active">
                                                                    <img src="../assets/images/Paladar.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Paladar-2-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Paladar-3-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Paladar-4-scaled.jpg" alt="">
                                                                </div>
                                                            </div>
    
    
                                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsSix" data-bs-slide="prev">
                                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Previous</span>
                                                            </button>
                                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsSix" data-bs-slide="next">
                                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Next</span>
                                                            </button>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            </div>
                                        </div>
    
                                        <div class="col-md-12 website-info-div">
                                            <h5>Paladar</h5>
                                        </div>
    
                                    </div>
    
    
                                </div>
                                
                                
                                <div class="display-none-row">
                                    <div class="row">
    
                                        <!-- Family First Cleaner -->
                                        <div class="col-md-4 h-100">
                                        <div class="col-md-12 website-img-div">
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal18" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/Family-First-Cleaners-1-1-scaled.jpg" alt="">
                                            </a>
                                            
                                            <div class="modal fade" id="exampleModal18" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
    
                                                    <div class="modal-body">
    
                                                        <div id="carouselExampleIndicatorsSeven" class="carousel slide" data-bs-ride="carousel">
    
    
                                                            <div class="carousel-indicators">
                                                                <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsSeven" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                    <img src="../assets/images/Family-First-Cleaners-1-1-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsSeven" data-bs-slide-to="1" aria-label="Slide 2">
                                                                    <img src="../assets/images/Family-First-Cleaners-2-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsSeven" data-bs-slide-to="2" aria-label="Slide 3">
                                                                    <img src="../assets/images/Family-First-Cleaners-3-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsSeven" data-bs-slide-to="3" aria-label="Slide 3">
                                                                    <img src="../assets/images/Family-First-Cleaners-4-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                            </div>
    
    
                                                            <div class="carousel-inner">
                                                                <div class="carousel-item active">
                                                                    <img src="../assets/images/Family-First-Cleaners-1-1-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Family-First-Cleaners-2-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Family-First-Cleaners-3-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Family-First-Cleaners-4-scaled.jpg" alt="">
                                                                </div>
                                                            </div>
    
    
                                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsSeven" data-bs-slide="prev">
                                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Previous</span>
                                                            </button>
                                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsSeven" data-bs-slide="next">
                                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Next</span>
                                                            </button>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            </div>
                                        </div>
    
                                        <div class="col-md-12 website-info-div">
                                            <h5>Family First Cleaner</h5>
                                        </div>
    
                                        </div>
    
                                        <!-- Logixer -->
                                        <div class="col-md-4 h-100">
                                        <div class="col-md-12 website-img-div">
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal19" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/Logixer-1-scaled.jpg" alt="">
                                            </a>
                                            
                                            <div class="modal fade" id="exampleModal19" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
    
                                                    <div class="modal-body">
    
                                                        <div id="carouselExampleIndicatorsEight" class="carousel slide" data-bs-ride="carousel">
    
    
                                                            <div class="carousel-indicators">
                                                                <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsEight" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                    <img src="../assets/images/Logixer-1-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsEight" data-bs-slide-to="1" aria-label="Slide 2">
                                                                    <img src="../assets/images/Logixer-2-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsEight" data-bs-slide-to="2" aria-label="Slide 3">
                                                                    <img src="../assets/images/Logixer-3-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsEight" data-bs-slide-to="3" aria-label="Slide 3">
                                                                    <img src="../assets/images/Logixer-4-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                            </div>
    
    
                                                            <div class="carousel-inner">
                                                                <div class="carousel-item active">
                                                                    <img src="../assets/images/Logixer-1-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Logixer-2-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Logixer-3-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Logixer-4-scaled.jpg" alt="">
                                                                </div>
                                                            </div>
    
    
                                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsEight" data-bs-slide="prev">
                                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Previous</span>
                                                            </button>
                                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsEight" data-bs-slide="next">
                                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Next</span>
                                                            </button>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            </div>
                                        </div>
    
                                        <div class="col-md-12 website-info-div">
                                            <h5>Logixer</h5>
                                        </div>
    
                                        </div>
    
                                        <!-- True Clean -->
                                        <div class="col-md-4 h-100">
                                        <div class="col-md-12 website-img-div">
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal20" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/True-Clean-1-scaled.jpg" alt="">
                                            </a>
                                            
                                            <div class="modal fade" id="exampleModal20" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
    
                                                    <div class="modal-body">
    
                                                        <div id="carouselExampleIndicatorsNine" class="carousel slide" data-bs-ride="carousel">
    
    
                                                            <div class="carousel-indicators">
                                                                <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsNine" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                    <img src="../assets/images/True-Clean-1-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsNine" data-bs-slide-to="1" aria-label="Slide 2">
                                                                    <img src="../assets/images/True-Clean-2-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsNine" data-bs-slide-to="2" aria-label="Slide 3">
                                                                    <img src="../assets/images/True-Clean-3-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsNine" data-bs-slide-to="3" aria-label="Slide 3">
                                                                    <img src="../assets/images/True-Clean-4-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                            </div>
    
    
                                                            <div class="carousel-inner">
                                                                <div class="carousel-item active">
                                                                    <img src="../assets/images/True-Clean-1-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/True-Clean-2-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/True-Clean-3-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/True-Clean-4-scaled.jpg" alt="">
                                                                </div>
                                                            </div>
    
    
                                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsNine" data-bs-slide="prev">
                                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Previous</span>
                                                            </button>
                                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsNine" data-bs-slide="next">
                                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Next</span>
                                                            </button>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            </div>
                                        </div>
    
                                        <div class="col-md-12 website-info-div">
                                            <h5>True Clean</h5>
                                        </div>
    
                                        </div>
    
                                        <!-- Brady Towing -->
                                        <div class="col-md-4 h-100">
                                            <div class="col-md-12 website-img-div">
                                                <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal21" data-bs-whatever="@getbootstrap">
                                                    <img src="../assets/images/Brady-Towing-1-scaled.jpg" alt="">
                                                </a>
                                                
                                                <div class="modal fade" id="exampleModal21" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog">
                                                        <div class="modal-content">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
    
                                                            <div class="modal-body">
    
                                                                <div id="carouselExampleIndicatorsTen" class="carousel slide" data-bs-ride="carousel">
    
    
                                                                    <div class="carousel-indicators">
                                                                        <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsTen" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                            <img src="../assets/images/Brady-Towing-1-scaled.jpg" class="d-block" alt="...">
                                                                        </button>
                                                                        <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTen" data-bs-slide-to="1" aria-label="Slide 2">
                                                                            <img src="../assets/images/Brady-Towing-2-scaled.jpg" class="d-block" alt="...">
                                                                        </button>
                                                                        <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTen" data-bs-slide-to="2" aria-label="Slide 3">
                                                                            <img src="../assets/images/Brady-Towing-3-scaled.jpg" class="d-block" alt="...">
                                                                        </button>
                                                                        <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTen" data-bs-slide-to="3" aria-label="Slide 3">
                                                                            <img src="../assets/images/Brady-Towing-4-scaled.jpg" class="d-block" alt="...">
                                                                        </button>
                                                                    </div>
    
    
                                                                    <div class="carousel-inner">
                                                                        <div class="carousel-item active">
                                                                            <img src="../assets/images/Brady-Towing-1-scaled.jpg" alt="">
                                                                        </div>
                                                                        <div class="carousel-item">
                                                                            <img src="../assets/images/Brady-Towing-2-scaled.jpg" alt="">
                                                                        </div>
                                                                        <div class="carousel-item">
                                                                            <img src="../assets/images/Brady-Towing-3-scaled.jpg" alt="">
                                                                        </div>
                                                                        <div class="carousel-item">
                                                                            <img src="../assets/images/Brady-Towing-4-scaled.jpg" alt="">
                                                                        </div>
                                                                    </div>
    
    
                                                                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsTen" data-bs-slide="prev">
                                                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                        <span class="visually-hidden">Previous</span>
                                                                    </button>
                                                                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsTen" data-bs-slide="next">
                                                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                        <span class="visually-hidden">Next</span>
                                                                    </button>
                                                                </div>
                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                            
                                                </div>
                                            </div>
    
                                            <div class="col-md-12 website-info-div">
                                                <h5>Brady Towing</h5>
                                            </div>
    
                                        </div>
    
                                        <!-- Bomba Tacos -->
                                        <div class="col-md-4 h-100">
                                            <div class="col-md-12 website-img-div">
                                                <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal22" data-bs-whatever="@getbootstrap">
                                                    <img src="../assets/images/Bomba-Tacos-1-scaled.jpg" alt="">
                                                </a>
                                                
                                                <div class="modal fade" id="exampleModal22" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog">
                                                        <div class="modal-content">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
    
                                                            <div class="modal-body">
    
                                                                <div id="carouselExampleIndicatorsEleven" class="carousel slide" data-bs-ride="carousel">
    
    
                                                                    <div class="carousel-indicators">
                                                                        <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsEleven" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                            <img src="../assets/images/Bomba-Tacos-1-scaled.jpg" class="d-block" alt="...">
                                                                        </button>
                                                                        <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsEleven" data-bs-slide-to="1" aria-label="Slide 2">
                                                                            <img src="../assets/images/Bomba-Tacos-2-scaled.jpg" class="d-block" alt="...">
                                                                        </button>
                                                                        <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsEleven" data-bs-slide-to="2" aria-label="Slide 3">
                                                                            <img src="../assets/images/Bomba-Tacos-2-scaled.jpg" class="d-block" alt="...">
                                                                        </button>
                                                                        <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsEleven" data-bs-slide-to="3" aria-label="Slide 3">
                                                                            <img src="../assets/images/Bomba-Tacos-2-scaled.jpg" class="d-block" alt="...">
                                                                        </button>
                                                                    </div>
    
    
                                                                    <div class="carousel-inner">
                                                                        <div class="carousel-item active">
                                                                            <img src="../assets/images/Bomba-Tacos-1-scaled.jpg" alt="">
                                                                        </div>
                                                                        <div class="carousel-item">
                                                                            <img src="../assets/images/Bomba-Tacos-2-scaled.jpg" alt="">
                                                                        </div>
                                                                        <div class="carousel-item">
                                                                            <img src="../assets/images/Bomba-Tacos-2-scaled.jpg" alt="">
                                                                        </div>
                                                                        <div class="carousel-item">
                                                                            <img src="../assets/images/Bomba-Tacos-2-scaled.jpg" alt="">
                                                                        </div>
                                                                    </div>
    
    
                                                                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsEleven" data-bs-slide="prev">
                                                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                        <span class="visually-hidden">Previous</span>
                                                                    </button>
                                                                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsEleven" data-bs-slide="next">
                                                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                        <span class="visually-hidden">Next</span>
                                                                    </button>
                                                                </div>
                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                            
                                                </div>
                                            </div>
    
                                            <div class="col-md-12 website-info-div">
                                                <h5>Bomba Tacos</h5>
                                            </div>
    
                                        </div>
    
                                        <!-- Cow Cleaner -->
                                        <div class="col-md-4 h-100">
                                            <div class="col-md-12 website-img-div">
                                                <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal23" data-bs-whatever="@getbootstrap">
                                                    <img src="../assets/images/Cow-Cleaners-1-scaled.jpg" alt="">
                                                </a>
                                                
                                                <div class="modal fade" id="exampleModal23" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog">
                                                        <div class="modal-content">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
    
                                                            <div class="modal-body">
    
                                                                <div id="carouselExampleIndicatorsTwelev" class="carousel slide" data-bs-ride="carousel">
    
    
                                                                    <div class="carousel-indicators">
                                                                        <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsTwelev" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                            <img src="../assets/images/Cow-Cleaners-1-scaled.jpg" class="d-block" alt="...">
                                                                        </button>
                                                                        <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwelev" data-bs-slide-to="1" aria-label="Slide 2">
                                                                            <img src="../assets/images/Cow-Cleaners-2-scaled.jpg" class="d-block" alt="...">
                                                                        </button>
                                                                        <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwelev" data-bs-slide-to="2" aria-label="Slide 3">
                                                                            <img src="../assets/images/Cow-Cleaners-2-scaled.jpg" class="d-block" alt="...">
                                                                        </button>
                                                                        <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwelev" data-bs-slide-to="3" aria-label="Slide 3">
                                                                            <img src="../assets/images/Cow-Cleaners-2-scaled.jpg" class="d-block" alt="...">
                                                                        </button>
                                                                    </div>
    
    
                                                                    <div class="carousel-inner">
                                                                        <div class="carousel-item active">
                                                                            <img src="../assets/images/Cow-Cleaners-1-scaled.jpg" alt="">
                                                                        </div>
                                                                        <div class="carousel-item">
                                                                            <img src="../assets/images/Cow-Cleaners-2-scaled.jpg" alt="">
                                                                        </div>
                                                                        <div class="carousel-item">
                                                                            <img src="../assets/images/Cow-Cleaners-2-scaled.jpg" alt="">
                                                                        </div>
                                                                        <div class="carousel-item">
                                                                            <img src="../assets/images/Cow-Cleaners-2-scaled.jpg" alt="">
                                                                        </div>
                                                                    </div>
    
    
                                                                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsTwelev" data-bs-slide="prev">
                                                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                        <span class="visually-hidden">Previous</span>
                                                                    </button>
                                                                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsTwelev" data-bs-slide="next">
                                                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                        <span class="visually-hidden">Next</span>
                                                                    </button>
                                                                </div>
                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                            
                                                </div>
                                            </div>
    
                                            <div class="col-md-12 website-info-div">
                                                <h5>Cow Cleaner</h5>
                                            </div>
    
                                        </div>
    
                                        
                                    </div>
                                </div>
                
                                
                            </div>
    
    
                        </div>
    
                        <div class="tab-pane fade" id="website" role="tabpanel" aria-labelledby="profile-tab">
    
    
                            <div class="row justify-content-center">
                                <div class="col-md-12 text-center m-5">
                                    <h2 class="text-uppercase"> <b> websites projects </b> </h2>
                                </div>
                            </div>
        
    
    
                            <div class="col-md-12">
                                <div class="row">
    
                                        
                                    <!-- Paladar -->
                                    <div class="col-md-4 h-100">
                                        <div class="col-md-12 website-img-div">
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal30" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/Paladar.jpg" alt="">
                                            </a>
                                            
                                            <div class="modal fade" id="exampleModal30" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
    
                                                    <div class="modal-body">
    
                                                        <div id="carouselExampleIndicatorsNinteen" class="carousel slide" data-bs-ride="carousel">
    
    
                                                            <div class="carousel-indicators">
                                                                <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsNinteen" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                    <img src="../assets/images/Paladar.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsNinteen" data-bs-slide-to="1" aria-label="Slide 2">
                                                                    <img src="../assets/images/Paladar-2-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsNinteen" data-bs-slide-to="2" aria-label="Slide 3">
                                                                    <img src="../assets/images/Paladar-3-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsNinteen" data-bs-slide-to="3" aria-label="Slide 3">
                                                                    <img src="../assets/images/Paladar-4-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                            </div>
    
    
                                                            <div class="carousel-inner">
                                                                <div class="carousel-item active">
                                                                    <img src="../assets/images/Paladar.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Paladar-2-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Paladar-3-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Paladar-4-scaled.jpg" alt="">
                                                                </div>
                                                            </div>
    
    
                                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsNinteen" data-bs-slide="prev">
                                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Previous</span>
                                                            </button>
                                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsNinteen" data-bs-slide="next">
                                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Next</span>
                                                            </button>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            </div>
                                        </div>
    
                                        <div class="col-md-12 website-info-div">
                                            <h5>Paladar</h5>
                                        </div>
    
                                    </div>
    
        
                                    <!-- Family First Cleaner -->
                                    <div class="col-md-4 h-100">
                                    <div class="col-md-12 website-img-div">
                                        <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal29" data-bs-whatever="@getbootstrap">
                                            <img src="../assets/images/Family-First-Cleaners-1-1-scaled.jpg" alt="">
                                        </a>
                                        
                                        <div class="modal fade" id="exampleModal29" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>

                                                <div class="modal-body">

                                                    <div id="carouselExampleIndicatorsEightteen" class="carousel slide" data-bs-ride="carousel">


                                                        <div class="carousel-indicators">
                                                            <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsEightteen" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                <img src="../assets/images/Family-First-Cleaners-1-1-scaled.jpg" class="d-block" alt="...">
                                                            </button>
                                                            <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsEightteen" data-bs-slide-to="1" aria-label="Slide 2">
                                                                <img src="../assets/images/Family-First-Cleaners-2-scaled.jpg" class="d-block" alt="...">
                                                            </button>
                                                            <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsEightteen" data-bs-slide-to="2" aria-label="Slide 3">
                                                                <img src="../assets/images/Family-First-Cleaners-3-scaled.jpg" class="d-block" alt="...">
                                                            </button>
                                                            <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsEightteen" data-bs-slide-to="3" aria-label="Slide 3">
                                                                <img src="../assets/images/Family-First-Cleaners-4-scaled.jpg" class="d-block" alt="...">
                                                            </button>
                                                        </div>


                                                        <div class="carousel-inner">
                                                            <div class="carousel-item active">
                                                                <img src="../assets/images/Family-First-Cleaners-1-1-scaled.jpg" alt="">
                                                            </div>
                                                            <div class="carousel-item">
                                                                <img src="../assets/images/Family-First-Cleaners-2-scaled.jpg" alt="">
                                                            </div>
                                                            <div class="carousel-item">
                                                                <img src="../assets/images/Family-First-Cleaners-3-scaled.jpg" alt="">
                                                            </div>
                                                            <div class="carousel-item">
                                                                <img src="../assets/images/Family-First-Cleaners-4-scaled.jpg" alt="">
                                                            </div>
                                                        </div>


                                                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsEightteen" data-bs-slide="prev">
                                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                            <span class="visually-hidden">Previous</span>
                                                        </button>
                                                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsEightteen" data-bs-slide="next">
                                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                            <span class="visually-hidden">Next</span>
                                                        </button>
                                                    </div>
                                                
                                                </div>
                                            </div>
                                        </div>
                                    
                                        </div>
                                    </div>

                                    <div class="col-md-12 website-info-div">
                                        <h5>Family First Cleaner</h5>
                                    </div>

                                    </div>
    
                                        
                                    <!-- Logixer -->
                                    <div class="col-md-4 h-100">
                                        <div class="col-md-12 website-img-div">
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal28" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/Logixer-1-scaled.jpg" alt="">
                                            </a>
                                            
                                            <div class="modal fade" id="exampleModal28" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
    
                                                    <div class="modal-body">
    
                                                        <div id="carouselExampleIndicatorsSeventeen" class="carousel slide" data-bs-ride="carousel">
    
    
                                                            <div class="carousel-indicators">
                                                                <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsSeventeen" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                    <img src="../assets/images/Logixer-1-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsSeventeen" data-bs-slide-to="1" aria-label="Slide 2">
                                                                    <img src="../assets/images/Logixer-2-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsSeventeen" data-bs-slide-to="2" aria-label="Slide 3">
                                                                    <img src="../assets/images/Logixer-3-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsSeventeen" data-bs-slide-to="3" aria-label="Slide 3">
                                                                    <img src="../assets/images/Logixer-4-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                            </div>
    
    
                                                            <div class="carousel-inner">
                                                                <div class="carousel-item active">
                                                                    <img src="../assets/images/Logixer-1-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Logixer-2-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Logixer-3-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Logixer-4-scaled.jpg" alt="">
                                                                </div>
                                                            </div>
    
    
                                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsSeventeen" data-bs-slide="prev">
                                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Previous</span>
                                                            </button>
                                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsSeventeen" data-bs-slide="next">
                                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Next</span>
                                                            </button>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            </div>
                                        </div>
    
                                        <div class="col-md-12 website-info-div">
                                            <h5>Logixer</h5>
                                        </div>
    
                                    </div>
    
                                    
                                    <!-- True Clean -->
                                    <div class="col-md-4 h-100">
                                        <div class="col-md-12 website-img-div">
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal27" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/True-Clean-1-scaled.jpg" alt="">
                                            </a>
                                            
                                            <div class="modal fade" id="exampleModal27" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
    
                                                    <div class="modal-body">
    
                                                        <div id="carouselExampleIndicatorsSixteen" class="carousel slide" data-bs-ride="carousel">
    
    
                                                            <div class="carousel-indicators">
                                                                <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsSixteen" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                    <img src="../assets/images/True-Clean-1-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsSixteen" data-bs-slide-to="1" aria-label="Slide 2">
                                                                    <img src="../assets/images/True-Clean-2-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsSixteen" data-bs-slide-to="2" aria-label="Slide 3">
                                                                    <img src="../assets/images/True-Clean-3-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsSixteen" data-bs-slide-to="3" aria-label="Slide 3">
                                                                    <img src="../assets/images/True-Clean-4-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                            </div>
    
    
                                                            <div class="carousel-inner">
                                                                <div class="carousel-item active">
                                                                    <img src="../assets/images/True-Clean-1-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/True-Clean-2-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/True-Clean-3-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/True-Clean-4-scaled.jpg" alt="">
                                                                </div>
                                                            </div>
    
    
                                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsSixteen" data-bs-slide="prev">
                                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Previous</span>
                                                            </button>
                                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsSixteen" data-bs-slide="next">
                                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Next</span>
                                                            </button>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            </div>
                                        </div>
    
                                        <div class="col-md-12 website-info-div">
                                            <h5>True Clean</h5>
                                        </div>
    
                                    </div>
    
   
                                    <!-- Brady Towing -->
                                    <div class="col-md-4 h-100">
                                        <div class="col-md-12 website-img-div">
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal26" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/Brady-Towing-1-scaled.jpg" alt="">
                                            </a>
                                            
                                            <div class="modal fade" id="exampleModal26" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                <div class="modal-dialog">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>

                                                        <div class="modal-body">

                                                            <div id="carouselExampleIndicatorsFifteen" class="carousel slide" data-bs-ride="carousel">


                                                                <div class="carousel-indicators">
                                                                    <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsFifteen" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                        <img src="../assets/images/Brady-Towing-1-scaled.jpg" class="d-block" alt="...">
                                                                    </button>
                                                                    <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsFifteen" data-bs-slide-to="1" aria-label="Slide 2">
                                                                        <img src="../assets/images/Brady-Towing-2-scaled.jpg" class="d-block" alt="...">
                                                                    </button>
                                                                    <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsFifteen" data-bs-slide-to="2" aria-label="Slide 3">
                                                                        <img src="../assets/images/Brady-Towing-3-scaled.jpg" class="d-block" alt="...">
                                                                    </button>
                                                                    <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsFifteen" data-bs-slide-to="3" aria-label="Slide 3">
                                                                        <img src="../assets/images/Brady-Towing-4-scaled.jpg" class="d-block" alt="...">
                                                                    </button>
                                                                </div>


                                                                <div class="carousel-inner">
                                                                    <div class="carousel-item active">
                                                                        <img src="../assets/images/Brady-Towing-1-scaled.jpg" alt="">
                                                                    </div>
                                                                    <div class="carousel-item">
                                                                        <img src="../assets/images/Brady-Towing-2-scaled.jpg" alt="">
                                                                    </div>
                                                                    <div class="carousel-item">
                                                                        <img src="../assets/images/Brady-Towing-3-scaled.jpg" alt="">
                                                                    </div>
                                                                    <div class="carousel-item">
                                                                        <img src="../assets/images/Brady-Towing-4-scaled.jpg" alt="">
                                                                    </div>
                                                                </div>


                                                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsFifteen" data-bs-slide="prev">
                                                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                    <span class="visually-hidden">Previous</span>
                                                                </button>
                                                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsFifteen" data-bs-slide="next">
                                                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                    <span class="visually-hidden">Next</span>
                                                                </button>
                                                            </div>
                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                        
                                            </div>
                                        </div>

                                        <div class="col-md-12 website-info-div">
                                            <h5>Brady Towing</h5>
                                        </div>

                                    </div>
    

                                    <!-- Classy Wear -->
                                    <div class="col-md-4 h-100">
                                        <div class="col-md-12 website-img-div">
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal25" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/classy-wear.jpg" alt="">
                                            </a>
                                            
                                            <div class="modal fade" id="exampleModal25" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                <div class="modal-dialog">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>

                                                        <div class="modal-body">

                                                            <div id="carouselExampleIndicatorsFourteen" class="carousel slide" data-bs-ride="carousel">


                                                                <div class="carousel-indicators">
                                                                    <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsFourteen" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                        <img src="../assets/images/classy-wear.jpg" class="d-block" alt="...">
                                                                    </button>
                                                                    <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsFourteen" data-bs-slide-to="1" aria-label="Slide 2">
                                                                        <img src="../assets/images/Classy-wear-1.jpg" class="d-block" alt="...">
                                                                    </button>
                                                                    <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsFourteen" data-bs-slide-to="2" aria-label="Slide 3">
                                                                        <img src="../assets/images/Classy-wear-1.jpg" class="d-block" alt="...">
                                                                    </button>
                                                                    <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsFourteen" data-bs-slide-to="3" aria-label="Slide 3">
                                                                        <img src="../assets/images/Classy-wear-1.jpg" class="d-block" alt="...">
                                                                    </button>
                                                                </div>


                                                                <div class="carousel-inner">
                                                                    <div class="carousel-item active">
                                                                        <img src="../assets/images/classy-wear.jpg" alt="">
                                                                    </div>
                                                                    <div class="carousel-item">
                                                                        <img src="../assets/images/Classy-wear-1.jpg" alt="">
                                                                    </div>
                                                                    <div class="carousel-item">
                                                                        <img src="../assets/images/Classy-wear-1.jpg" alt="">
                                                                    </div>
                                                                    <div class="carousel-item">
                                                                        <img src="../assets/images/Classy-wear-1.jpg" alt="">
                                                                    </div>
                                                                </div>


                                                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsFourteen" data-bs-slide="prev">
                                                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                    <span class="visually-hidden">Previous</span>
                                                                </button>
                                                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsFourteen" data-bs-slide="next">
                                                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                    <span class="visually-hidden">Next</span>
                                                                </button>
                                                            </div>
                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                        
                                            </div>
                                        </div>

                                        <div class="col-md-12 website-info-div">
                                            <h5>Classy Wear</h5>
                                        </div>

                                    </div>


                                    
                                </div>
    

                                <!-- <div class="display-none-row">
                                    <div class="row">
                                        
                                    </div>
                                </div> -->
                                
                                
                            </div>
    
    
    
                        </div>
                        
                        <div class="tab-pane fade" id="mobile" role="tabpanel" aria-labelledby="contact-tab">
    
                            <div class="row justify-content-center">
                                <div class="col-md-12 text-center m-5">
                                    <h2 class="text-uppercase"> <b> mobile application projects </b> </h2>
                                </div>
                            </div>
    
    
                            <div class="col-md-12">
                                <div class="row">

                                    <!-- Bomba Tocus -->
                                    <div class="col-md-4 h-100">
                                        <div class="col-md-12 website-img-div">
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal31" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/Bomba-Tacos-1-scaled.jpg" alt="">
                                            </a>
                                            
                                            <div class="modal fade" id="exampleModal31" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                <div class="modal-dialog">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>

                                                        <div class="modal-body">

                                                            <div id="carouselExampleIndicatorsTwenty" class="carousel slide" data-bs-ride="carousel">


                                                                <div class="carousel-indicators">
                                                                    <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsTwenty" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                        <img src="../assets/images/Bomba-Tacos-1-scaled.jpg" class="d-block" alt="...">
                                                                    </button>
                                                                    <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwenty" data-bs-slide-to="1" aria-label="Slide 2">
                                                                        <img src="../assets/images/Bomba-Tacos-2-scaled.jpg" class="d-block" alt="...">
                                                                    </button>
                                                                    <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwenty" data-bs-slide-to="2" aria-label="Slide 3">
                                                                        <img src="../assets/images/Bomba-Tacos-2-scaled.jpg" class="d-block" alt="...">
                                                                    </button>
                                                                    <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwenty" data-bs-slide-to="3" aria-label="Slide 3">
                                                                        <img src="../assets/images/Bomba-Tacos-2-scaled.jpg" class="d-block" alt="...">
                                                                    </button>
                                                                </div>


                                                                <div class="carousel-inner">
                                                                    <div class="carousel-item active">
                                                                        <img src="../assets/images/Bomba-Tacos-1-scaled.jpg" alt="">
                                                                    </div>
                                                                    <div class="carousel-item">
                                                                        <img src="../assets/images/Bomba-Tacos-2-scaled.jpg" alt="">
                                                                    </div>
                                                                    <div class="carousel-item">
                                                                        <img src="../assets/images/Bomba-Tacos-2-scaled.jpg" alt="">
                                                                    </div>
                                                                    <div class="carousel-item">
                                                                        <img src="../assets/images/Bomba-Tacos-2-scaled.jpg" alt="">
                                                                    </div>
                                                                </div>


                                                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsTwenty" data-bs-slide="prev">
                                                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                    <span class="visually-hidden">Previous</span>
                                                                </button>
                                                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsTwenty" data-bs-slide="next">
                                                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                    <span class="visually-hidden">Next</span>
                                                                </button>
                                                            </div>
                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                        
                                            </div>
                                        </div>

                                        <div class="col-md-12 website-info-div">
                                            <h5>Bomba Tacos</h5>
                                        </div>

                                    </div>
                                    
                                    <!-- Cow Cleaner -->
                                    <div class="col-md-4 h-100">
                                        <div class="col-md-12 website-img-div">
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal32" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/Cow-Cleaners-1-scaled.jpg" alt="">
                                            </a>
                                            
                                            <div class="modal fade" id="exampleModal32" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                <div class="modal-dialog">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>

                                                        <div class="modal-body">

                                                            <div id="carouselExampleIndicatorsTwentyone" class="carousel slide" data-bs-ride="carousel">


                                                                <div class="carousel-indicators">
                                                                    <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsTwentyone" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                        <img src="../assets/images/Cow-Cleaners-1-scaled.jpg" class="d-block" alt="...">
                                                                    </button>
                                                                    <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentyone" data-bs-slide-to="1" aria-label="Slide 2">
                                                                        <img src="../assets/images/Cow-Cleaners-2-scaled.jpg" class="d-block" alt="...">
                                                                    </button>
                                                                    <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentyone" data-bs-slide-to="2" aria-label="Slide 3">
                                                                        <img src="../assets/images/Cow-Cleaners-2-scaled.jpg" class="d-block" alt="...">
                                                                    </button>
                                                                    <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentyone" data-bs-slide-to="3" aria-label="Slide 3">
                                                                        <img src="../assets/images/Cow-Cleaners-2-scaled.jpg" class="d-block" alt="...">
                                                                    </button>
                                                                </div>


                                                                <div class="carousel-inner">
                                                                    <div class="carousel-item active">
                                                                        <img src="../assets/images/Cow-Cleaners-1-scaled.jpg" alt="">
                                                                    </div>
                                                                    <div class="carousel-item">
                                                                        <img src="../assets/images/Cow-Cleaners-2-scaled.jpg" alt="">
                                                                    </div>
                                                                    <div class="carousel-item">
                                                                        <img src="../assets/images/Cow-Cleaners-2-scaled.jpg" alt="">
                                                                    </div>
                                                                    <div class="carousel-item">
                                                                        <img src="../assets/images/Cow-Cleaners-2-scaled.jpg" alt="">
                                                                    </div>
                                                                </div>


                                                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsTwentyone" data-bs-slide="prev">
                                                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                    <span class="visually-hidden">Previous</span>
                                                                </button>
                                                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsTwentyone" data-bs-slide="next">
                                                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                    <span class="visually-hidden">Next</span>
                                                                </button>
                                                            </div>
                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                        
                                            </div>
                                        </div>

                                        <div class="col-md-12 website-info-div">
                                            <h5>Cow Cleaner</h5>
                                        </div>
    
                                    </div>
                                    
                                    <!-- Family First Cleaner -->
                                    <div class="col-md-4 h-100">
                                        <div class="col-md-12 website-img-div">
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal33" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/Family-First-Cleaners-1-1-scaled.jpg" alt="">
                                            </a>
                                            
                                            <div class="modal fade" id="exampleModal33" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>

                                                    <div class="modal-body">

                                                        <div id="carouselExampleIndicatorsTwentytwo" class="carousel slide" data-bs-ride="carousel">


                                                            <div class="carousel-indicators">
                                                                <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsTwentytwo" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                    <img src="../assets/images/Family-First-Cleaners-1-1-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentytwo" data-bs-slide-to="1" aria-label="Slide 2">
                                                                    <img src="../assets/images/Family-First-Cleaners-2-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentytwo" data-bs-slide-to="2" aria-label="Slide 3">
                                                                    <img src="../assets/images/Family-First-Cleaners-3-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentytwo" data-bs-slide-to="3" aria-label="Slide 3">
                                                                    <img src="../assets/images/Family-First-Cleaners-4-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                            </div>


                                                            <div class="carousel-inner">
                                                                <div class="carousel-item active">
                                                                    <img src="../assets/images/Family-First-Cleaners-1-1-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Family-First-Cleaners-2-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Family-First-Cleaners-3-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Family-First-Cleaners-4-scaled.jpg" alt="">
                                                                </div>
                                                            </div>


                                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsTwentytwo" data-bs-slide="prev">
                                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Previous</span>
                                                            </button>
                                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsTwentytwo" data-bs-slide="next">
                                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Next</span>
                                                            </button>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            </div>
                                        </div>

                                        <div class="col-md-12 website-info-div">
                                            <h5>Family First Cleaner</h5>
                                        </div>

                                    </div>
                                
                                    <!-- Exchange -->
                                    <div class="col-md-4 h-100">
                                        <div class="col-md-12 website-img-div">
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal34" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/Exchange-1-scaled.jpg" alt="">
                                            </a>
                                            
                                            <div class="modal fade" id="exampleModal34" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>

                                                    <div class="modal-body">

                                                        <div id="carouselExampleIndicatorsTwentythree" class="carousel slide" data-bs-ride="carousel">


                                                            <div class="carousel-indicators">
                                                                <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsTwentythree" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                    <img src="../assets/images/Exchange-1-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentythree" data-bs-slide-to="1" aria-label="Slide 2">
                                                                    <img src="../assets/images/Exchange-2-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentythree" data-bs-slide-to="2" aria-label="Slide 3">
                                                                    <img src="../assets/images/Exchange-2-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentythree" data-bs-slide-to="3" aria-label="Slide 3">
                                                                    <img src="../assets/images/Exchange-2-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                            </div>


                                                            <div class="carousel-inner">
                                                                <div class="carousel-item active">
                                                                    <img src="../assets/images/Exchange-1-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Exchange-2-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Exchange-2-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Exchange-2-scaled.jpg" alt="">
                                                                </div>
                                                            </div>


                                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsTwentythree" data-bs-slide="prev">
                                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Previous</span>
                                                            </button>
                                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsTwentythree" data-bs-slide="next">
                                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Next</span>
                                                            </button>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            </div>
                                        </div>

                                        <div class="col-md-12 website-info-div">
                                            <h5>Exchange</h5>
                                        </div>

                                    </div>

                                    <!-- Logixer -->
                                    <div class="col-md-4 h-100">
                                        <div class="col-md-12 website-img-div">
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal35" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/Logixer-1-scaled.jpg" alt="">
                                            </a>
                                            
                                            <div class="modal fade" id="exampleModal35" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
    
                                                    <div class="modal-body">
    
                                                        <div id="carouselExampleIndicatorsTwentyfour" class="carousel slide" data-bs-ride="carousel">
    
    
                                                            <div class="carousel-indicators">
                                                                <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsTwentyfour" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                    <img src="../assets/images/Logixer-1-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentyfour" data-bs-slide-to="1" aria-label="Slide 2">
                                                                    <img src="../assets/images/Logixer-2-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentyfour" data-bs-slide-to="2" aria-label="Slide 3">
                                                                    <img src="../assets/images/Logixer-3-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentyfour" data-bs-slide-to="3" aria-label="Slide 3">
                                                                    <img src="../assets/images/Logixer-4-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                            </div>
    
    
                                                            <div class="carousel-inner">
                                                                <div class="carousel-item active">
                                                                    <img src="../assets/images/Logixer-1-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Logixer-2-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Logixer-3-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Logixer-4-scaled.jpg" alt="">
                                                                </div>
                                                            </div>
    
    
                                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsTwentyfour" data-bs-slide="prev">
                                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Previous</span>
                                                            </button>
                                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsTwentyfour" data-bs-slide="next">
                                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Next</span>
                                                            </button>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            </div>
                                        </div>
    
                                        <div class="col-md-12 website-info-div">
                                            <h5>Logixer</h5>
                                        </div>
    
                                    </div>

                                    <!-- Classy Wear -->
                                    <div class="col-md-4 h-100">
                                        <div class="col-md-12 website-img-div">
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal36" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/classy-wear.jpg" alt="">
                                            </a>
                                            
                                            <div class="modal fade" id="exampleModal36" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                <div class="modal-dialog">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>

                                                        <div class="modal-body">

                                                            <div id="carouselExampleIndicatorsTwentyfive" class="carousel slide" data-bs-ride="carousel">


                                                                <div class="carousel-indicators">
                                                                    <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsTwentyfive" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                        <img src="../assets/images/classy-wear.jpg" class="d-block" alt="...">
                                                                    </button>
                                                                    <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentyfive" data-bs-slide-to="1" aria-label="Slide 2">
                                                                        <img src="../assets/images/Classy-wear-1.jpg" class="d-block" alt="...">
                                                                    </button>
                                                                    <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentyfive" data-bs-slide-to="2" aria-label="Slide 3">
                                                                        <img src="../assets/images/Classy-wear-1.jpg" class="d-block" alt="...">
                                                                    </button>
                                                                    <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentyfive" data-bs-slide-to="3" aria-label="Slide 3">
                                                                        <img src="../assets/images/Classy-wear-1.jpg" class="d-block" alt="...">
                                                                    </button>
                                                                </div>


                                                                <div class="carousel-inner">
                                                                    <div class="carousel-item active">
                                                                        <img src="../assets/images/classy-wear.jpg" alt="">
                                                                    </div>
                                                                    <div class="carousel-item">
                                                                        <img src="../assets/images/Classy-wear-1.jpg" alt="">
                                                                    </div>
                                                                    <div class="carousel-item">
                                                                        <img src="../assets/images/Classy-wear-1.jpg" alt="">
                                                                    </div>
                                                                    <div class="carousel-item">
                                                                        <img src="../assets/images/Classy-wear-1.jpg" alt="">
                                                                    </div>
                                                                </div>


                                                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsTwentyfive" data-bs-slide="prev">
                                                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                    <span class="visually-hidden">Previous</span>
                                                                </button>
                                                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsTwentyfive" data-bs-slide="next">
                                                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                    <span class="visually-hidden">Next</span>
                                                                </button>
                                                            </div>
                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                        
                                            </div>
                                        </div>

                                        <div class="col-md-12 website-info-div">
                                            <h5>Classy Wear</h5>
                                        </div>

                                    </div>

                                </div>
                                
    
                                <!-- <div class="display-none-row">
                                    <div class="row">
                                        
                                    </div>
                                </div> -->

                            </div>
    
    
    
    
                        </div>
    
                        <div class="tab-pane fade" id="graphic" role="tabpanel" aria-labelledby="contact-tab">
    
    
    
                            <div class="row justify-content-center">
                                <div class="col-md-12 text-center m-5">
                                    <h2 class="text-uppercase"> <b> graphic design projects </b> </h2>
                                </div>
                            </div>
    
    
                            <div class="col-md-12">
                                <div class="row">
                                    
                                    <!-- Blue Sky -->
                                    <div class="col-md-4 h-100">
                                        
                                        <div class="col-md-12 website-img-div">                                    
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal37" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/Blue-Sky-scaled.jpg" alt="">
                                            </a>
                                            <div class="modal fade" id="exampleModal37" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
    
                                                    <div class="modal-body">
    
                                                        <div id="carouselExampleIndicatorsTwentysix" class="carousel slide" data-bs-ride="carousel">
    
    
                                                            <div class="carousel-indicators">
                                                                <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsTwentysix" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                    <img src="../assets/images/Blue-Sky-2-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentysix" data-bs-slide-to="1" aria-label="Slide 2">
                                                                    <img src="../assets/images/Blue-Sky-3-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentysix" data-bs-slide-to="2" aria-label="Slide 3">
                                                                    <img src="../assets/images/Blue-Sky-4-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentysix" data-bs-slide-to="3" aria-label="Slide 3">
                                                                    <img src="../assets/images/Blue-Sky-5-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                            </div>
    
    
                                                            <div class="carousel-inner">
                                                                <div class="carousel-item active">
                                                                    <img src="../assets/images/Blue-Sky-2-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Blue-Sky-3-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Blue-Sky-4-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Blue-Sky-5-scaled.jpg" alt="">
                                                                </div>
                                                            </div>
    
    
                                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsTwentysix" data-bs-slide="prev">
                                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Previous</span>
                                                            </button>
                                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsTwentysix" data-bs-slide="next">
                                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Next</span>
                                                            </button>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            </div>
                                        </div>
    
                                        <div class="col-md-12 website-info-div">
                                            <h5>Blue Sky</h5>
                                        </div>
                                        
    
                                    </div>
                                    
                                    <!-- Cleaning Automaters -->
                                    <div class="col-md-4 h-100">
                                        <div class="col-md-12 website-img-div">
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal38" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/cleaning-automaters-scaled.jpg" alt="">
                                            </a>
                                            
                                            <div class="modal fade" id="exampleModal38" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
    
                                                    <div class="modal-body">
    
                                                        <div id="carouselExampleIndicatorsTwentyseven" class="carousel slide" data-bs-ride="carousel">
    
    
                                                            <div class="carousel-indicators">
                                                                <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsTwentyseven" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                    <img src="../assets/images/cleaning-automaters-2-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentyseven" data-bs-slide-to="1" aria-label="Slide 2">
                                                                    <img src="../assets/images/cleaning-automaters-3-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentyseven" data-bs-slide-to="2" aria-label="Slide 3">
                                                                    <img src="../assets/images/cleaning-automaters-4-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentyseven" data-bs-slide-to="3" aria-label="Slide 3">
                                                                    <img src="../assets/images/cleaning-automaters-5-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                            </div>
    
    
                                                            <div class="carousel-inner">
                                                                <div class="carousel-item active">
                                                                    <img src="../assets/images/cleaning-automaters-2-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/cleaning-automaters-3-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/cleaning-automaters-4-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/cleaning-automaters-5-scaled.jpg" alt="">
                                                                </div>
                                                            </div>
    
    
                                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsTwentyseven" data-bs-slide="prev">
                                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Previous</span>
                                                            </button>
                                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsTwentyseven" data-bs-slide="next">
                                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Next</span>
                                                            </button>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            </div>
                                        </div>
    
                                        <div class="col-md-12 website-info-div">
                                            <h5>Cleaning Automaters</h5>
                                        </div>
    
                                    </div>
                                    
                                    <!-- G.I Party -->
                                    <div class="col-md-4 h-100">
                                        <div class="col-md-12 website-img-div">
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal39" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/G.I-Party-scaled.jpg" alt="">
                                            </a>
                                            
                                            <div class="modal fade" id="exampleModal39" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
    
                                                    <div class="modal-body">
    
                                                        <div id="carouselExampleIndicatorsTwentyeight" class="carousel slide" data-bs-ride="carousel">
    
    
                                                            <div class="carousel-indicators">
                                                                <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsTwentyeight" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                    <img src="../assets/images/G.I-Party-2-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentyeight" data-bs-slide-to="1" aria-label="Slide 2">
                                                                    <img src="../assets/images/G.I-Party-3-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentyeight" data-bs-slide-to="2" aria-label="Slide 3">
                                                                    <img src="../assets/images/G.I-Party-4-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentyeight" data-bs-slide-to="3" aria-label="Slide 3">
                                                                    <img src="../assets/images/G.I-Party-5-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                            </div>
    
    
                                                            <div class="carousel-inner">
                                                                <div class="carousel-item active">
                                                                    <img src="../assets/images/G.I-Party-2-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/G.I-Party-3-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/G.I-Party-4-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/G.I-Party-5-scaled.jpg" alt="">
                                                                </div>
                                                            </div>
    
    
                                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsTwentyeight" data-bs-slide="prev">
                                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Previous</span>
                                                            </button>
                                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsTwentyeight" data-bs-slide="next">
                                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Next</span>
                                                            </button>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            </div>
                                        </div>
    
                                        <div class="col-md-12 website-info-div">
                                            <h5>G.I Party</h5>
                                        </div>
    
                                    </div>

                                    <!-- Mop-A-Lot -->
                                    <div class="col-md-4 h-100">
                                        <div class="col-md-12 website-img-div">
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal40" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/Mop-A-Lot-scaled.jpg" alt="">
                                            </a>
                                            
                                            <div class="modal fade" id="exampleModal40" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
    
                                                    <div class="modal-body">
    
                                                        <div id="carouselExampleIndicatorsTwentynine" class="carousel slide" data-bs-ride="carousel">
    
    
                                                            <div class="carousel-indicators">
                                                                <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsTwentynine" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                    <img src="../assets/images/Mop-A-Lot-2-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentynine" data-bs-slide-to="1" aria-label="Slide 2">
                                                                    <img src="../assets/images/Mop-A-Lot-3-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentynine" data-bs-slide-to="2" aria-label="Slide 3">
                                                                    <img src="../assets/images/Mop-A-Lot-4-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsTwentynine" data-bs-slide-to="3" aria-label="Slide 3">
                                                                    <img src="../assets/images/Mop-A-Lot-5-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                            </div>
    
    
                                                            <div class="carousel-inner">
                                                                <div class="carousel-item active">
                                                                    <img src="../assets/images/Mop-A-Lot-2-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Mop-A-Lot-3-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Mop-A-Lot-4-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Mop-A-Lot-5-scaled.jpg" alt="">
                                                                </div>
                                                            </div>
    
    
                                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsTwentynine" data-bs-slide="prev">
                                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Previous</span>
                                                            </button>
                                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsTwentynine" data-bs-slide="next">
                                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Next</span>
                                                            </button>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            </div>
                                        </div>
    
                                        <div class="col-md-12 website-info-div">
                                            <h5>Mop-A-Lot</h5>
                                        </div>
    
                                    </div>
                                    
                                    <!-- Sparkle Easy -->
                                    <div class="col-md-4 h-100">
                                        <div class="col-md-12 website-img-div">
                                            <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal41" data-bs-whatever="@getbootstrap">
                                                <img src="../assets/images/Sparkle-Easy-scaled.jpg" alt="">
                                            </a>
                                            
                                            <div class="modal fade" id="exampleModal41" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">Portfolio</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
    
                                                    <div class="modal-body">
    
                                                        <div id="carouselExampleIndicatorsThirty" class="carousel slide" data-bs-ride="carousel">
    
    
                                                            <div class="carousel-indicators">
                                                                <button type="button" class="button-img active" data-bs-target="#carouselExampleIndicatorsThirty" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1">
                                                                    <img src="../assets/images/Sparkle-Easy-2-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsThirty" data-bs-slide-to="1" aria-label="Slide 2">
                                                                    <img src="../assets/images/Sparkle-Easy-3-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsThirty" data-bs-slide-to="2" aria-label="Slide 3">
                                                                    <img src="../assets/images/Sparkle-Easy-4-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                                <button type="button" class="button-img" data-bs-target="#carouselExampleIndicatorsThirty" data-bs-slide-to="3" aria-label="Slide 3">
                                                                    <img src="../assets/images/Sparkle-Easy-5-scaled.jpg" class="d-block" alt="...">
                                                                </button>
                                                            </div>
    
    
                                                            <div class="carousel-inner">
                                                                <div class="carousel-item active">
                                                                    <img src="../assets/images/Sparkle-Easy-2-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Sparkle-Easy-3-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Sparkle-Easy-4-scaled.jpg" alt="">
                                                                </div>
                                                                <div class="carousel-item">
                                                                    <img src="../assets/images/Sparkle-Easy-5-scaled.jpg" alt="">
                                                                </div>
                                                            </div>
    
    
                                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsThirty" data-bs-slide="prev">
                                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Previous</span>
                                                            </button>
                                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsThirty" data-bs-slide="next">
                                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span class="visually-hidden">Next</span>
                                                            </button>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            </div>
                                        </div>
    
                                        <div class="col-md-12 website-info-div">
                                            <h5>Sparkle Easy</h5>
                                        </div>
    
                                    </div>
    
                                </div>

                                <!-- <div class="display-none-row">
                                    <div class="row">

                                    </div>
                                </div> -->
                            
                            </div>
    
    
    
    
                        </div>
                        
                      </div>
                      <div class="row justify-content-center">
                        <div class="col-md-4">
                            <div class="row justify-content-center">
                                <div class="col-md-12">
                                    <center>
                                        <button class="websites-seemore-btn" @click="seeMore()"> See More  </button>
                                    </center>
                                </div>
                                <div class="col-md-12">
                                    <center>
                                        <button class="websites-seeless-btn" @click="seeLess()"> See Less </button>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    
    
    
    
    
    
    </template>
    
<script>
import $ from 'jquery'
export default {
    name:'PortfolioComp',

    setup(){

        
    const seeMore = ()=>{
       
        $('.display-none-row').slideToggle(2000);        
        
        $('.websites-seemore-btn').css('display','none');
        $('.websites-seeless-btn').css('display','block');
    }


    const seeLess = ()=>{
     
        $('.display-none-row').slideUp(2000);
      
        $('.websites-seeless-btn').css('display','none');
        $('.websites-seemore-btn').css('display','block');  
    }

  
    return{
        seeMore,
        seeLess,
    }
}

}


</script>

<style>

</style>