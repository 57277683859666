<template>

<!-- OUR CORE SERVICES SECTION -->
<div class="container-fluid">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="row justify-content-center">
                    <div class="col-md-5 text-center our-core-services-content-text" data-aos="fade-up" data-aos-delay="200">
                        <h2><b>OUR CORE SERVICES</b></h2>
                        <p>
                            We offer expertise for end-to-end product design and development, withsolutions tailor-made to fit your product’s unique requirements.
                        </p>
                        <p>Home <span> / Services</span></p>
                    </div>
                    <div class="col-md-7 text-center our-core-services-content-img-div" data-aos="zoom-in" data-aos-delay="200">
                        <img src="../assets/images/services-front-content-img.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- SERVICES -- ABOUT-US SECTION -->
<div class="container-fluid services-about-us-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 services-about-us-div">
                <div class="row justify-content-center">
                    <div class="col-md-6 services-about-us-text-div" data-aos="fade-up" data-aos-delay="200">
                        <img src="../assets/images/Squiggly-globe.png" class="squiggly-img" alt="">
                        <h4><b> ABOUT US </b></h4><br>
                        <p>
                            A Team Of Experts Who Aim To Deliver Quality Projects And Services. In This Era Technology Is Everything. So We Totally Understand The Need Of Having An Amazing Online Presence Which Is Key To Business Growth Whether You Are An Entrepreneur, An Agency, Someone With A Unique Idea, Or A Well-Established Industry. Everyone Needs IT Services And We Come Into The Picture There. We Have Experts For Every Important Skill That Needs To Make Anything You Would Require. With Our Logix, We Can Help You Grow.
                        </p>
                    </div>
                    <div class="col-md-6 services-about-us-img-div" data-aos="fade-up" data-aos-delay="300">
                        <img src="../assets/images/services-about.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Website Development Section -->
<div class="container-fluid">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 content-div">
                <div class="row">
                    <div class="col-md-6 web-dev-text-div" data-aos="fade-up" data-aos-delay="200">
                        <img src="../assets/images/web-dev-logo.png" alt="">
                        <h4>WEBSITE DEVELOPMENT</h4>
                        <p>
                            From Simple Website Projects To Wholesome Web Products, Our Experts Can Work On All Kinds Of Web Development Techniques. From Unique Web Designs To Stable And Efficient Development, Our Process Promises You Excellent Websites. From Simple WordPress Websites To Fully Custom Functionality-Based Laravel Websites We Got Your Back For Whichever Category You Would Need.
                        </p>
                    </div>
                    <div class="col-md-6 text-center web-dev-img-div" data-aos="fade-up" data-aos-delay="300">
                        <img src="../assets/images/web-dev-content-img.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>    
</div>


<!-- Mobile Application Section -->
<div class="container-fluid">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 content-div">
                <div class="row">
                    <div class="col-md-6 p-4 text-center mobile-app-img-div" data-aos="fade-up" data-aos-delay="200">
                        <img src="../assets/images/mobile-app.png" alt="">
                    </div>
                    <div class="col-md-6 p-4 mobile-app-text-div" data-aos="fade-up" data-aos-delay="300">
                        <img src="../assets/images/mobile-application-logo.png" alt="">
                        <h4>MOBILE APPLICATION</h4>
                        <p>
                            We Have Our Skilled Experts Who Can Build Any Application For You Whether It Is Android Or Ios Or Hybrid. Our Coolest UI Designs And Amazing Development Skills Would Give You The Perfect Combination Of A Mobile Application That People Would Love To Use All The Time. Our Experts Will Design, Develop, Integrate, And Build From Scratch Using The Latest App Development Skills To Give You The Best Digital Product.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</div>


<!-- Graphic designing Section -->
<div class="container-fluid">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 content-div">
                <div class="row">
                    <div class="col-md-6 p-4 graphic-desgn-text-div" data-aos="fade-up" data-aos-delay="200">
                        <img src="../assets/images/graphic-design-logo.png" alt="">
                        <h4>GRAPHIC DESIGNING</h4>
                        <p>
                            UX Design, UI Design, Logos, Branding, Social Media Content, Brochures, Website Designs, Company Portfolios, And Much More Things Are Designed And Provided To Hundreds Of Clients And All Are Satisfied Clients Of Ours. You Imagine And We Design It For You Or You Just Have To Ask For Something Cool And We Design It From Scratch With The Perfect Blend Of Unique Creative Imagination And The Right Designing Tools.
                        </p>
                    </div>
                    <div class="col-md-6 p-4 text-center graphic-desgn-img-div" data-aos="fade-up" data-aos-delay="300">
                        <img src="../assets/images/graphic-img.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>    
</div>


<!-- E-Commerce Solution Section -->
<div class="container-fluid">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 content-div">
                <div class="row">
                    <div class="col-md-6 text-center p-4 e-commerce-img-div" data-aos="fade-up" data-aos-delay="200">
                        <img src="../assets/images/e-commerce-imgs.png" alt="">
                    </div>
                    <div class="col-md-6 p-4 e-commerce-text-div" data-aos="fade-up" data-aos-delay="300">
                        <img src="../assets/images/e-coomerce-logo.png" alt="">
                        <h4>E-COMMERCE SOLUTION</h4>
                        <p>
                            Who Isn’t Into Online Shopping Anymore? Whether It Is A Well-Known Store Or A Business Or A Huge Industry Or A New Start-Up, Everyone Is Shifting Their Business Online. And We Have Everything That It Takes To Make Sure That You Can Supply Your Goods Online. Shopify Or Woo-Commerce Whatever Your Business Will Need To Grow Online We Will Make It Happen For You.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</div>


<!-- Digital Marketing Section -->
<div class="container-fluid">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 content-div">
                <div class="row">
                    <div class="col-md-6 p-4 e-commerce-text-div" data-aos="fade-up" data-aos-delay="200">
                        <img src="../assets/images/digital-marketing-logo.png" alt="">
                        <h4>DIGITAL MARKETING</h4>
                        <p>
                            Having An Amazing Website Or An Application Is Cool But The Most Amazing Part Is When You Get Noticed Online And You Turn It Into Beneficial Leads. We Have The Right Tech That Will Be Needed For The Online Marketing Of Your Company Or Business So You Can Get Huge Leads With The Targetted Audience In Mind To Have Exponential Growth As Compared To Your Competitors.
                        </p>
                    </div>
                    <div class="col-md-6 text-center p-4 e-commerce-img-div" data-aos="fade-up" data-aos-delay="400">
                        <img src="../assets/images/digital-marketing-img.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>    
</div>


<!-- Search Engine Optimization Section -->
<div class="container-fluid">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 content-div">
                <div class="row">
                    <div class="col-md-6 text-center p-4 e-commerce-img-div" data-aos="fade-up" data-aos-delay="200">
                        <img src="../assets/images/seo-img.png" alt="">
                    </div>
                    <div class="col-md-6 p-4 e-commerce-text-div" data-aos="fade-up" data-aos-delay="300">
                        <img src="../assets/images/seo-logo.png" alt="">
                        <h4>SEARCH ENGINE OPTIMIZATION</h4>
                        <p>
                            SEO Is Something That Your Content Would Need To Keep Breathing As Long As Possible. On-Page SEO, Off-Page SEO, Facebook Content SEO, Youtube SEO, Website’s General SEO Or Anything That Comes Across Your Mind Would Need The Right Keywords And Other SEO Skills That We Know How To Deliver To Your Content And We Would Happily Do It For You.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</div>



</template>

<script>
export default {
    name: 'ServicesComp',
}
</script>
<style>
</style>