<template>
  <!-- Cotact Us SECTION -->
  <div class="container-fluid">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="row justify-content-center">
            <div class="col-md-5 text-center contact-us-content-text" data-aos="fade-up">
              <h2><b>CONTACT US</b></h2>
              <p>Home <span> / Contact</span></p>
            </div>
            <div class="col-md-7 text-center contact-us-content-img-div">
              <img src="../assets/images/contact-us-front-content-img.png" alt="" data-aos="zoom-in"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- OUR CONTACT & DROP YOUR MASSAGE HERE SECTION -->
  <div class="container-fluid">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6 text-center contact-us-text">
          <h1>CONTACT INFORMATION</h1>
        </div>
      </div>
    </div>
  </div>

 

    <!-- OUR CONTACT & DROP YOUR MASSAGE HERE SECTION -->
    <div class="container-fluid contact-dropmsg-section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-12 contact-info-bottom p-5">
                    <div class="row">
                        <div class="col-md-4 contact-info-div">
                            
                            <h4> CONTACT INFORMATION  </h4>
                            <div class="col-md-12 contact-info-bottom-box">
                                <img src="../assets/images/form-background-img.png" class="contact-back-img" alt="">
                                <div class="col-md-12 m-2 contact-box-div">
                                    <div class="row m-0">
                                        <div class="col-md-2 contact-icon-div">
                                            <i class="fa-solid fa-phone m-2"></i>
                                        </div>
                                        <div class="col-md-9 contact-icon-text-div">
                                            <a href="callto:+923367944092">+923367944092</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12 m-2 contact-box-div">
                                    <div class="row m-0">
                                        <div class="col-md-2 contact-icon-div">
                                            <i class="fa-solid fa-envelope m-2"></i>
                                        </div>
                                        <div class="col-md-9 contact-icon-text-div">
                                            <a href="mailto:nfo@logixers.com">info@logixers.com</a> 
                                        </div>
                                    </div>
                                </div>
            
                                <div class="col-md-12 m-2 contact-box-div">
                                    <div class="row m-0">
                                        <div class="col-md-2 contact-icon-div">
                                            <i class="fa-solid fa-location-dot m-2"></i>
                                        </div>
                                        <div class="col-md-9 contact-icon-text-div">
                                            <span>Kohinoor City</span> 
                                        </div>
                                    </div>
                                </div> 

                                <div class="col-md-12 social-icon-infobox p-2">
                                    <a target="blank" href="https://www.facebook.com/logixer"><i class="fa-brands fa-facebook-f"></i></a>
                                    <a target="blank" href="https://instagram.com/logixers?igshid=MzRlODBiNWFlZA=="><i class="fa-brands fa-instagram"></i></a>
                                    <a target="blank" href="https://www.linkedin.com/in/logixers"><i class="fa-brands fa-linkedin"></i></a>
                                    <a target="blank" href="https://twitter.com/logixers?t=tFNP7N5qUrX1UmG1n14_2w&s=09"><i class="fa-brands fa-twitter"></i></a>
                                </div>                
                            </div>
                        </div>
                        <div class="col-md-8 drop-your-msg-div">
                            <h4>DROP YOUR MESSAGE</h4>
                            <div class="col-md-12 drop-msg-bottom-box"> 
                                <form @submit.prevent="contact">                       
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-floating mb-3">
                                            <input type="text" v-model="form.fname" class="form-control" id="floatingInput" placeholder="name@example.com">
                                            <label for="floatingInput">First Name</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-floating mb-3">
                                            <input type="text" v-model="form.lname" class="form-control" id="floatingInput" placeholder="name@example.com">
                                            <label for="floatingInput">Last Name</label>
                                        </div>
                                    </div>
                                </div>  
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-floating mb-3">
                                            <input type="email" v-model="form.email" class="form-control" id="floatingInput" placeholder="name@example.com">
                                            <label for="floatingInput">Email address</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-floating mb-3">
                                            <input type="number" v-model="form.phone" class="form-control" id="floatingInput" placeholder="name@example.com">
                                            <label for="floatingInput">Phone*</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-floating mb-3">
                                            <input type="text" v-model="form.subject" class="form-control" id="floatingInput" placeholder="name@example.com">
                                            <label for="floatingInput">Subject</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-floating mb-3">
                                            <textarea class="form-control" v-model="form.description" id="floatingInput" placeholder="name@example.com"></textarea>
                                            <label for="floatingInput">Tell Us A Little About Your Property</label>
                                        </div>
                                    </div>
                                </div>        
                                <div class="row justify-content-end">
                                    <div class="col-md-6 drop-msg-btn">
                                        <button class="drop-msg-btn">SUBMIT</button>
                                    </div>
                                </div>
                                </form>          
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  <div class="container-fluid map-section">
    <div class="container">
      <div class="row justify-content-center" style="margin-top: 50px; margin-bottom: 100px">
        <div class="col-md-12 map-div">
          <iframe height="500" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6810.127963441114!2d73.11368762348198!3d31.412363232045802!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x392268719ca1547d%3A0x6d137ff9f3401ab5!2sKohinoor%20City%2C%20Faisalabad%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1680521578628!5m2!1sen!2s" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/apiService.js';
import {reactive} from 'vue';
import Swal from 'sweetalert2';
export default {
  name: "ContactComp",
  setup(){
    const form = reactive({
        fname:'',
        lname:'',
        email:'',
        phone:'',
        subject:'',
        description:'',
    });
    const contact = () =>{
        apiClient.post('api/contact',form).then(res=>{
            if(res.data.success == true){
                    Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: res.data.message,
                    });
                }
        });
    } 
    return{
        contact,
        form
    }
  }
};
</script>

<style></style>
