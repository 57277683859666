<template>
   


<!-- E-Commerce Development Solutions Front Content  Section  -->
<div class="container-fluid e-commerce-front-content-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-12 e-commerce-front-content-div text-center" data-aos="fade-up" data-aos-delay="400">
                <h2 class="text-uppercase">E-Commerce Development Solutions</h2>
                <p>Online Shopping Is Becoming A Routine Thing Now. But To Make Sure The Right Kind Of Tools Is Used Is A Must-Have. This Is Exactly What Developers Serve With. We Have Shopify And Woo-Commerce Skilled Developers Who Make Websites For Your Buyers To Have An Amazing Online Shopping Experience.</p>
                <img src="../assets/images/e-commerce-front-content-img.png" alt="" data-aos="zoom-in" data-aos-delay="800">
            </div>
        </div>
    </div>
</div>


<!-- ECommerce Solutions WE PROVIDE -->
<div class="container-fluid e-commerce-solution-section">
    <div class="container">
        <div class="row justify-content-center m-0">
            <div class="col-md-12 text-center e-commerce-solution-content">
                <h3 class="text-uppercase" data-aos="fade-up" data-aos-delay="400">E-Commerce Solutions <br> WE PROVIDE </h3>
                <div class="row">
                    <div class="col-lg-4 col-md-6 wordpress-div" data-aos="fade-up" data-aos-delay="100">
                        <div class="col-md-12 img-div text-center">
                            <img src="../assets/images/wordpress-logo-img.png" alt="">
                        </div>
                        <div class="col-md-12 img-text-div">
                            <h6>WORDPRESS</h6>
                            <p>WordPress Is One Of The Most Amazing Tools To Create An Online Store. It Has All Important Plugins That Have Crucial Functionalities To Make The User Shopping Experience Smooth. </p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 shopify-div" data-aos="fade-up" data-aos-delay="200">
                        <div class="col-md-12 img-div text-center">
                            <img src="../assets/images/shopifylogo-img.png" alt="">
                        </div>
                        <div class="col-md-12 img-text-div">
                            <h6>SHOPIFY</h6>
                            <p>Shopify Is A One-Stop Corner For Online Shopping Store To Give Users An Amazing Experience Of Shopping Online. We Have A Team Of Experts Dedicated To Creating Online Shopify Stores. </p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 woo-div" data-aos="fade-up" data-aos-delay="300">
                        <div class="col-md-12 img-div text-center">
                            <img src="../assets/images/woooo-logo-img.png" alt="">
                        </div>
                        <div class="col-md-12 img-text-div">
                            <h6>WOO-COMMERCE</h6>
                            <p>Woo-Commerce Is A WordPress Plugin That Has All Requirements Of An Online Store From Shopping To Paying Online As Well And Our Experts Can Quickly Deliver A Woo-Commerce-Based Site. </p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 b2b-div" data-aos="fade-up" data-aos-delay="400">
                        <div class="col-md-12 img-div text-center">
                            <img src="../assets/images/b2b-logo-img.png" alt="">
                        </div>
                        <div class="col-md-12 img-text-div">
                            <h6>B2B</h6>
                            <p>Our Business To Business Solutions Are Expertly Made And They Have All Functions That Clients Can Think Of. We Make Them Custom Coded To Give Fully Optimized Content. </p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 b2c-div" data-aos="fade-up" data-aos-delay="500">
                        <div class="col-md-12 img-div text-center">
                            <img src="../assets/images/b2c-logo-img.png" alt="">
                        </div>
                        <div class="col-md-12 img-text-div">
                            <h6>B2C</h6>
                            <p>We Have Delivered A Lot Of B2C Stores Where Sellers Are Selling Their Goods To Their Target Buyers. We Have Experts Who Do It All For You.</p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>

        <div class="row justify-content-center m-0">


            <!-- <button class="btn btn-outline-success" type="submit">Get Started</button> -->
            <button type="button" class="col-3 btn request-consulation" data-bs-toggle="modal" data-bs-target="#ecommercemodalbtn" data-bs-whatever="@getbootstrap">
                Request A Free Consulaion
            </button>

            <div class="modal fade" id="ecommercemodalbtn" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Get Started & REQUEST A QUATE FORM</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                    <div class="col-md-12 drop-your-msg-div text-center">
                        <h4><b>REQUEST A QUATE FORM</b></h4>
                        <div class="col-md-12 drop-msg-bottom-box">                        
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-floating mb-3">
                                        <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com">
                                        <label for="floatingInput">First Name</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-3">
                                        <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com">
                                        <label for="floatingInput">Last Name</label>
                                    </div>
                                </div>
                            </div>  
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-floating mb-3">
                                        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
                                        <label for="floatingInput">Email address</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-floating mb-3">
                                        <input type="number" class="form-control" id="floatingInput" placeholder="name@example.com">
                                        <label for="floatingInput">Phone*</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-3">
                                        <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com">
                                        <label for="floatingInput">Subject</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-floating mb-3">
                                        <textarea cols="5" rows="8" class="form-control" id="floatingInput" placeholder="name@example.com"></textarea>
                                        <label for="floatingInput">Tell Us A Little About Your Property</label>
                                    </div>
                                </div>
                            </div>        
                            <div class="row justify-content-end">
                                <div class="col-md-4">
                                    <button class="modal-submit-btn">SUBMIT</button>
                                </div>
                            </div>          
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>


        </div>
    </div>
</div>


<!-- Send us an email ----- Section -->
<div class="container-fluid send-us-an-email-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 send-us-an-email-div">
                <div class="row">
                    <div class="col-md-6 p-5 send-us-an-email-text" data-aos="fade-up" data-aos-delay="100">
                        <h2 class="text-uppercase">Send us an email,to discuss <br> a new project.</h2>
                        <div class="col-md-12 text-center">
                            <router-link to="/contact" class="contact-us-btn"> CONTACT US </router-link>
                        </div>
                    </div>
                    <div class="col-md-6 send-us-an-email-img">
                        <img src="../assets/images/send-us-an-email-img.png" alt="" data-aos="zoom-in" data-aos-delay="200">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



    
</template>

<script>
export default {
    name:'EcommerceComp'
}
</script>