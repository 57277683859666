<template>
   



<!-- Digital Marketing Front Content Section -->
<div class="container-fluid digital-marketing-front-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-7 col-md-12 text-center" data-aos="fade-up" data-aos-delay="200">
                <h2 class="text-uppercase">Digital Marketing Services</h2>
                <p>Maximize Your Online Potential With Our Expert Digital Marketing Services. Our Team Creates Customized Solutions To Improve Website Rankings, Increase Traffic, And Drive Results.</p>
                <img src="../assets/images/digital-marketing-front-content-img.png" alt="" data-aos="zoom-in" data-aos-delay="800">
            </div>
        </div>
    </div>
</div>

<!-- in image Section -->
<div class="container-fluid in-img-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 text-center in-img-content-div">
                <h2 class="text-uppercase" data-aos="fade-up" data-aos-delay="200">How Digital Marketing Services Drive <br> Business Growth</h2>

                <div class="row">
                    <div class="col-lg-6 col-md-12 in-img-box">
                        <img src="../assets/images/in-img.png" alt="" data-aos="fade-up" data-aos-delay="200">
                    </div>
                    <div class="col-lg-6 col-md-12 text-start in-img-text-section" data-aos="fade-up" data-aos-delay="300">
                        <p>
                            Digital Marketing Services Are An Essential Component Of Business Success In Today’s Competitive Online Landscape. These Services Help Businesses Reach Their Target Audience Through A Variety Of Online Channels Such As Search Engines, Social Media, Email, And Websites. By Creating A Strong Online Presence And Using Data-Driven Tactics, Digital Marketing Services Can Drive Significant Business Growth. They Can Increase Brand Awareness, Attract More Qualified Leads, And Improve Conversion Rates.
                        <br>
                        <br>
                        One Of The Key Benefits Of Digital Marketing Services Is The Ability To Gain Valuable Insights Into Customer Behavio
                        </p>
                    </div>
                    <div class="col-md-12 in-img-text-section text-start" data-aos="fade-up" data-aos-delay="400">
                        <p>
                            This Information Can Be Used To Tailor Marketing Efforts And Offerings To Better Meet Customer Needs, Which Can Lead To Increased Customer Engagement And Loyalty. This, In Turn, Can Improve Customer Acquisition And Retention Rates, Resulting In Long-Term Business Growth And Success.
                        <br>    
                        <br>    
                        <br>    
                            Whether A Business Is Just Starting Out Or Looking To Expand, Digital Marketing Services Can Play A Crucial Role In Helping Them Reach Its Goals. By Leveraging The Latest Online Marketing Strategies And Technologies, Businesses Can Connect With Their Target Audience, Build Strong Relationships, And Achieve Their Desired Outcomes.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Powerful-Digital-Marketing Solutions WE PROVIDE Section -->
<div class="container-fluid powerful-digital-marketing-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 text-center powerfull-content-div">
                <h5 class="text-uppercase" data-aos="fade-up" data-aos-delay="200">Powerful Digital Marketing Solutions <br> WE PROVIDE</h5>
                <div class="row justify-content-center">        
                    <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                        <div class="social-media-marketing-div">
                            <div class="row justify-content-center">
                            <div class="col-md-12 text-center we-provide-imgs-div">
                                <img src="../assets/images/social-media-megaphone.png" alt="">
                            </div>
                            <div class="col-md-12 we-provide-imgs-text-div">
                                <h5 class="text-uppercase">Social Media Marketing</h5>
                                <p>
                                    Build Your Brand And Connect With Your Audience Through Our Social Media Marketing Services. Drive Engagement, Increase Followers, And Reach Your Target Market.                            
                                </p>
                            </div>                    
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                        <div class="web-enhancement-div">
                            <div class="row justify-content-center">
                            <div class="col-md-12 text-center we-provide-imgs-div">
                                <img src="../assets/images/web-development.png" alt="">
                            </div>
                            <div class="col-md-12 we-provide-imgs-text-div">
                                <h5 class="text-uppercase">web enhancement</h5>
                                <p>Enhance Your Online Image With Our Expert Web Enhancement Services. Increase Functionality, Attract Traffic, And Improve User Experience.</p>
                            </div>                    
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                        <div class="content-marketing-div">
                            <div class="row justify-content-center">
                            <div class="col-md-12 text-center we-provide-imgs-div">
                                <img src="../assets/images/content-marketing.png" alt="">
                            </div>
                            <div class="col-md-12 we-provide-imgs-text-div">
                                <h5 class="text-uppercase">content Marketing</h5>
                                <p>Drive Results With Our Content Marketing Services. Craft Engaging, Relevant, And Optimized Content To Attract Customers And Build Brand Authority. </p>
                            </div>                    
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                        <div class="seo-div">
                            <div class="row justify-content-center">
                            <div class="col-md-12 text-center we-provide-imgs-div">
                                <img src="../assets/images/SEO-rocket.png" alt="">
                            </div>
                            <div class="col-md-12 we-provide-imgs-text-div">
                                <h5 class="text-uppercase">SEARCH ENGINE OPTIMIZATION</h5>
                                <p>Our Search Engine Optimization (SEO) Services Help Businesses Improve Their Online Visibility And Search Engine Ranking. These Services May Include Keyword Research, Website Optimization, Content Creation, Link Building, And Technical SEO. </p>
                            </div>                    
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
                        <div class="e-mail-marketing-div">
                            <div class="row justify-content-center">
                            <div class="col-md-12 text-center we-provide-imgs-div">
                                <img src="../assets/images/e-mail-marketing.png" alt="">
                            </div>
                            <div class="col-md-12 we-provide-imgs-text-div">
                                <h5 class="text-uppercase">e-mail Marketing</h5>
                                <p>Our Email Marketing Is A Direct Communication Tool Used To Reach Customers And Prospects Through Electronic Mail. Email Marketing Services Provide Tools To Create, Send, And Track Email Campaigns To Promote Products, Services, And Brand Awareness. </p>
                            </div>                    
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
                        <div class="google-analytics-div">
                            <div class="row justify-content-center">
                            <div class="col-md-12 text-center we-provide-imgs-div">
                                <img src="../assets/images/google-analytics.png" alt="">
                            </div>
                            <div class="col-md-12 we-provide-imgs-text-div">
                                <h5 class="text-uppercase">google analytics</h5>
                                <p>Our Google Analytics Is A Free Web Analytics Service That Tracks And Reports Website Traffic. It Provides Valuable Insights Into Website Performance, User Behavior, And Marketing Effectiveness. Businesses Use Google Analytics To Measure Website Success And Reach Their Goals. </p>
                            </div>                    
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">

            <!-- <button class="btn btn-outline-success" type="submit">Get Started</button> -->
            <button type="button" class="col-3 btn request-consulation" data-bs-toggle="modal" data-bs-target="#digitalmarketingmodalbtn" data-bs-whatever="@getbootstrap">
                Request A Free Consulaion
            </button>

            <div class="modal fade" id="digitalmarketingmodalbtn" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Get Started & REQUEST A QUATE FORM</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                <div class="col-md-12 drop-your-msg-div text-center">
                    <h4><b>REQUEST A QUATE FORM</b></h4>
                    <div class="col-md-12 drop-msg-bottom-box">                        
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com">
                                    <label for="floatingInput">First Name</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com">
                                    <label for="floatingInput">Last Name</label>
                                </div>
                            </div>
                        </div>  
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
                                    <label for="floatingInput">Email address</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-floating mb-3">
                                    <input type="number" class="form-control" id="floatingInput" placeholder="name@example.com">
                                    <label for="floatingInput">Phone*</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com">
                                    <label for="floatingInput">Subject</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-floating mb-3">
                                    <textarea cols="5" rows="8" class="form-control" id="floatingInput" placeholder="name@example.com"></textarea>
                                    <label for="floatingInput">Tell Us A Little About Your Property</label>
                                </div>
                            </div>
                        </div>        
                        <div class="row justify-content-end">
                            <div class="col-md-4">
                                <button class="modal-submit-btn">SUBMIT</button>
                            </div>
                        </div>          
                    </div>
                </div>
                </div>
            </div>
            </div>
            </div>

        </div>
    </div>
</div>



    
</template>

<script>
export default {
    name:'DigitalMarketing'
}
</script>
<style>
</style>