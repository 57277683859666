<template>
<!-- Preloader -->
<!-- <div class="preloader"></div> -->
    <!-- Header Top along with social icons -->

    <div class="container-fluid head-icons">
        <div class="container">
            <div class="col-lg-12 col-md-12">
                <div class="row">
                    
                  <div class="col-lg-5 col-md-5 top-header-col-6">
                      <div class="row justify-content-center">
                          <div class="col-lg-6 col-md-8 top-header-social-icon">
                              <a target="blank" href="https://www.facebook.com/logixer"><i class="fa-brands fa-facebook-f"></i></a>
                              <a target="blank" href="https://instagram.com/logixers?igshid=MzRlODBiNWFlZA=="><i class="fa-brands fa-instagram"></i></a>
                              <a target="blank" href="https://www.linkedin.com/in/logixers"><i class="fa-brands fa-linkedin"></i></a>
                              <a target="blank" href="https://twitter.com/logixers?t=tFNP7N5qUrX1UmG1n14_2w&s=09"><i class="fa-brands fa-twitter"></i></a>
                          </div>    
                      </div>
                  </div>


                  <div class="col-md-7 top-header-col-6">
                      <div class="row">

                        <div class="col-lg-4 col-md-4 phone">
                            <div class="row m-0">
                              <div class="col-lg-2 col-md-12 p-0 icon-div">
                                <i class="fa-solid fa-phone"></i>
                              </div>
                              <div class="col-lg-9 col-md-12 icon-text">
                                <a href="tel:+923367944092">+923367944092</a>
                              </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 mail">
                          <div class="row m-0">
                            <div class="col-lg-2 col-md-12 icon-div">
                              <i class="fa-solid fa-envelope"></i>
                            </div>
                            <div class="col-lg-9 col-md-12 icon-text">
                              <a href="mailto:info@logixers.com">info@logixers.com</a> 
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4 col-md-4 location">
                          <div class="row m-0">
                            <div class="col-lg-2 col-md-12 icon-div">
                              <i class="fa-solid fa-location-dot"></i>
                            </div>
                            <div class="col-lg-9 col-md-12 icon-text">
                              <span>Kohinoor City</span> 
                            </div>
                          </div>
                        </div>

                      </div>
                  </div>

                </div>
            </div>
        </div>
    </div>

    <!-- Navbar start -->
   <div class="container-fluid sticky-for-mobile">
    <div class="container navbar-container">
        <nav class="navbar navbar-expand-md">
            <div class="container-fluid">
              <router-link to="/" class="navbar-brand img-logo-destop" @click="removemenu()"> 
                <img src="../assets/images/logixer_logo.png" alt="">
              </router-link>
              <router-link to="/" class="navbar-brand img-logo-mobile" @click="removemenu()"> 
                <img src="../assets/images/white-logo.png" alt="">
              </router-link>
              
              <button class="navbar-toggler nav-toggler-btn" @click="dropNav()" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon">
                    <i class="fas fa-bars"></i>
                </span>
              </button>
        
        <div class="col-lg-6 col-md-6 collapse navbar-collapse menu-btn-div" id="navbarSupportedContent" style="transition: .5s height linear!important;">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <router-link to="/" class="nav-link menu-link" @click="removemenu()"> Home </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/about" class="nav-link about menu-link" @click="removemenu()"> About </router-link>
                  </li>
                  <li class="nav-item" v-on:click="changeClass">
                    <router-link to="/services" class="nav-link menu-link" @click="removemenu()"> Services </router-link>

                  
                        <ul class="nav-services-show">
                          <li>
                              <router-link to="/websitedevelopment" @click="removemenu()"> Website Development </router-link><br>
                          </li>
                          
                          <li>
                            <router-link to="/graphicdesigning" @click="removemenu()"> Graphic Designing </router-link><br>
                          </li>
                          <li>
                            <router-link to="/seo" @click="removemenu()"> SEO </router-link><br>
                          </li>
                          <li>
                            <router-link to="/digitalmarketing" @click="removemenu()"> Digital Marketing </router-link><br>
                          </li>
                          <li>
                            <router-link to="/ecommerce" @click="removemenu()"> E-Commerce Solution </router-link><br>
                          </li>
                        </ul>
                      

                  </li>
                  <li class="nav-item">
                    <router-link to="/career" class="nav-link menu-link" @click="removemenu()"> Career </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/contact" class="nav-link menu-link" @click="removemenu()"> Contact </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/portfolio" class="nav-link menu-link" @click="removemenu()"> Portfolio </router-link>
                  </li>
                  <!-- <li class="nav-item">
                    <router-link to="/blogs" :class="`nav-link menu-link`"> Blogs </router-link>
                  </li> -->
                </ul>

             
                <form class="d-flex getstrated-btn">
                  <router-link to="/contact" class="btn btn-primary"> Get Started </router-link>
                </form>
                


              </div>
            </div>
          </nav>
    </div>
</div>

</template>
<style scoped>

</style>
<script>


import $ from 'jquery'
// import { ref } from 'vue';
export default {
  name:'NavbarHeader',

setup(){  
  

  
  const removemenu =()=>{
    $('.nav-toggler-btn').removeClass('collapsed')
    $('.menu-btn-div').removeClass('show')
  }

  const dropNav =()=>{
      $('nav-toggler-btn').on('click', function(){
        
        $('menu-btn-div').slideToggle('menu-btn-div!important');
        console.log('working');
      })
  }

  return{
    removemenu,
    dropNav,
  }

}
}

</script>