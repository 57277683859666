import { createRouter, createWebHistory } from "vue-router";



import Index from '../components/Index';
import About from '../components/About';
import Services from '../components/Services';
import Career from '../components/Career';
import Contact from '../components/Contact';
import Portfolio from '../components/Portfolio';
// import Blogs from '../components/Blogs';
import WebsiteDevelopment from '../components/WebsiteDevelopment';
import GraphicDesigning from '../components/GraphicDesigning';
import Seo from '../components/Seo';
import DigitalMarketing from '../components/DigitalMarketing';
import Ecommerce from '../components/Ecommerce';
import Faq from '../components/Faq';
// import About from '../components/About';
const routes =[
    {
        path:"/",
        name: "Index",
        component: Index,
    },
    {
        path:"/about",
        name: "About",
        component: About,
    },
    {
        path:"/services",
        name: "Services",
        component: Services,
    },
    {
        path:"/career",
        name: "Career",
        component: Career,
    },
    {
        path:"/contact",
        name: "Contact",
        component: Contact,
    },
    {
        path:"/portfolio",
        name: "Portfolio",
        component: Portfolio,
    },
    // {
    //     path:"/blogs",
    //     name: "Blogs",
    //     component: Blogs,
    // },
    {
        path:"/websitedevelopment",
        name: "WebsiteDevelopment",
        component: WebsiteDevelopment,
    },
    {
        path:"/graphicdesigning",
        name: "GraphicDesigning",
        component: GraphicDesigning,
    },
    {
        path:"/seo",
        name: "Seo",
        component: Seo,
    },
    {
        path:"/digitalmarketing",
        name: "DigitalMarketing",
        component: DigitalMarketing,
    },
    {
        path:"/ecommerce",
        name: "Ecommerce",
        component: Ecommerce,
    },
    {
        path:"/faq",
        name: "Faq",
        component: Faq,
    },
    // {
    //     path:"/digitalmarketing",
    //     name: "DigitalMarketing",
    //     component: DigitalMarketing,
    // },
   
];
const router = createRouter({
    history:createWebHistory(),
    routes
});
export default router;