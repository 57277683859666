<template>


    <!-- We Provide IT Solutions DIV -->
    <div class="container-fluid home-front-content-section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-12 it-solution-div">
                    <div class="row justify-content-center">
                        <div class="col-lg-5 col-md-5 col-sm-4 we-prodit-it-solution-text-div">
                            <div data-aos="fade-up">
                                <h1><b> NEW HEIGHTS TO YOUR IT NEEDS! </b></h1>
                                <p class="front-p-text">Logixers Are Here To Give New Heights To Your Tech Needs With Exceptional IT Services.   </p>
                                <router-link to="/contact" class="content-btn">Get Sterted</router-link>
                            </div>
                                
                            <div class="row">
                                                                
                                <!-- Rating div -->
                                <div class="col-lg-12 col-md-12 col-sm-12 rating-div">
                                    <div class="row p-2">
                                        <div class="col-lg-4 col-md-4 col-sm-4 text-center rating-numbr">
                                            <div class="row justify-content-center">
                                                <div class="col-lg-5 col-md-5 col-sm-5 text-center rating-point-div" style="margin-top: 10px;">
                                                    <span class="m-0 p-0"><h5 class="text-black">4.8</h5></span>
                                                    <p>
                                                    <span class="fa-solid fa-star"></span>
                                                    <span class="fa-solid fa-star"></span>
                                                    <span class="fa-solid fa-star"></span>
                                                    <span class="fa-solid fa-star"></span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="row justify-content-center">
                                                <div class="col-md-12 p-0" style="margin-top: 10px;">
                                                    <h6>Customer Rating</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 rating-numbr">
                                            <div class="col-md-12 text-center" style="margin-top: 10px;">
                                                <h5>20</h5>
                                            </div>
                                            <div class="col-md-12 text-center" style="margin-top: 20px;">
                                                <h6>Designer Available</h6>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 rating-numbr">
                                            <div class="col-md-12 text-center" style="margin-top: 10px;">
                                                <h5>350+</h5>
                                            </div>
                                            <div class="col-md-12 p-0 text-center" style="margin-top: 20px;">
                                                <h6>Project Completed</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>                        
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 col-sm-8 content-img-div" data-aos="zoom-in">
                            <img src="../assets/images/content-main-img.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- About us info -->
    <div class="container-fluid aboutus-info" data-aos="fade-up">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-12 home-about-us-div">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6 p-0 about-us-content-div" data-aos="fade-up" data-aos-delay="100">
                            <img src="../assets/images/about-img1.jpg" class="aboutus-img1" alt="">
                           <div class="col-md-12 p-4">
                            <h4>We Serve Till You’re Happy, </h4>
                            <!-- <font-awesome-icon icon="check-circle" /> -->
                            <p>
                                Logixers Make Sure That Our Clients Are Fully Satisfied And We Keep Improvising Our Work Even In The Development Phase So That When It Reaches The Final User Rarely Is Anything Needed To Add Any Further. This Is Because We Are Dedicated To Your Needs More Than You Know.                            
                            </p>
                           </div>
                            
                        </div>
                        <div class="col-lg-4 col-md-6 p-0 about-us-content-div" data-aos="fade-down" data-aos-delay="200">
                            <img src="../assets/images/abount-img2.jpg" class="aboutus-img2" alt="">
                        </div>
                        <div class="col-lg-4 col-md-12 about-us-content-div" data-aos="fade-down" data-aos-delay="300">
                            <div class="col-md-12 aboutus-info-margin">
                                <h5> <b> ABOUT US </b></h5>
                                <p>
                                    We Are Experts In IT Having Years Of Experience To Fully Meet Your Requirements. Logixers Will Make Sure All Your Needs Are Fulfilled And We Keep Working Unless You Get What You Want. We Are A Team Of Experts Who Aim To Deliver Quality Projects And Services. In This Era Technology Is Everything. So We Totally Understand The Need Of Having An Amazing Online Presence Which Is Key To Business Growth Whether You Are An Entrepreneur, An Agency, Someone With A Unique Idea, Or A Well-Established Industry.
                                </p>
                                <router-link to="/about">
                                    <button class="aboutus-readmore-btn">Read More</button>
                                </router-link>
                            </div>         
                        </div>
                </div>
            
            </div> 
            </div> 
        </div>
    </div>

    <!-- How We Can Help You -->
  <div class="container-fluid help-div">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-10 text-center">
                    <h1>HOW WE CAN HELP YOU</h1>
                    <p class="text-center">
                        Imagine a place that only existed to help you be an amazing Digital Agency and scale your business. Who’s daily focus is to help your business grow, increase your reputation as a leading Marketer and increase your profits.
                    </p>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row justify-content-center">

                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                    <div class="carding">
                        <img src="../assets/images/web-dev-img.png" height="70" alt="">
                        <h4>WEBSITE DEVELOPMENT</h4>
                        <p>
                            From Simple Website Projects To Wholesome Web Products, Our Experts Can Do Any Kind Of Web Development Work You Might Think Of.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                    <div class="carding">
                        <img src="../assets/images/mob-dev-img.png" height="70" alt="">
                        <h4>MOBILE APLICATIONS</h4>
                        <p>
                            Our Mobile App Solutions Will Give You The Best Results To Achieve Your Aims For Any Mobile Application You Would Think Of.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                    <div class="carding">
                        <img src="../assets/images/e-commerce-img.png" height="70" alt="">
                        <h4>E-COMMERCE SOLUTION</h4>
                        <p>
                            Shopify, Woo Commerce, And All Sorts Of E-Commerce Tech An Online Business Would Need We’ve Got It Sorted Out For You.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                    <div class="carding">
                        <img src="../assets/images/graphic-div.png" height="70" alt="">
                        <h4>GRAPHIC DESIGNING</h4>
                        <p>
                            With Unique Imagination And All The Skills Needed To Put Them Into Reality, We Got The Best Team Of Designers To Enhance Your Online Presence.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
                    <div class="carding">
                        <img src="../assets/images/digital-marketing-div.png" height="70" alt="">
                        <h4>DIGITAL MARKETING</h4>
                        <p>
                            Getting Seen Online Is All That Matters Nowadays So You Can Get Maximum Leads. Our Experts Have The Right Tools To Give You The Boost To Get Maximum Output With The Exact Digital Marketing Needed To Get You Noticed.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
                    <div class="carding">
                        <img src="../assets/images/seo-div.png" height="70" alt="">
                        <h4>SEARCH ENGINE OPITMIZATION</h4>
                        <p>
                            Different Platforms Have Different SEO Needs To Get On Top Of Everyone. We Have Experts Who Can Do Their Best To Take You To Maximum Heights And Get Excellent Leads.
                        </p>
                    </div>
                </div>

            </div>
            </div>
            
        </div>
    </div>

    <!-- Why You Choose Us Div  -->
    <div class="container-fluid choose-us-div">
        <div class="container choose-us-line-seprator">
            <div class="row justify-content-center text-center">
                <div class="col-md-12 text-center">
                    <h1 class="m-5"> <b> WHY CHOOSE US </b></h1>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-12 col-md-12 choose-us-img">
                   
                </div>
            </div>
            <div class="row justify-content-center service-content-margin">
                <div class="col-md-3 service-content-div">
                    <p>
                        It’s Rare To Get A Loyal Team That Takes Care Of Your Work. That’s The Perk Of Working With Us, Once We Take Over All You Need Is To Sit Back And Relax To Get Things Done By Us.                    </p>
                </div>
                <div class="col-md-3 service-content-div">
                    <p>
                        Our Services Not Only Meet Your Expectations But Also Leave You In Awe By Delivering A Lot More Than You Expect. So You’d Question Yourself Why Not Choose Us Again?                    </p>
                </div>
                <div class="col-md-3 service-content-div">
                    <p>
                        Quality Is Our Standard. Your Online Appearance Represents Us As Well Not Only You. So No Matter How Much Quantity Is, One Thing We Never Compromise On Is Quality.                    </p>
                </div>
                <div class="col-md-3 service-content-div">
                    <p>
                        We Value Our Time And Your Money So We Design/Develop In A Way That Never Needs Restructuring Of Code Or Multiple Revisions Of Designs. We Get It Done Right On The First Attempt.                    </p>
                </div>
            </div>
        </div>
    </div>

    <!-- Our Recent Project Section -->
    <div class="container-fluid recent-margin">
        <div class="container choose-us-line-seprator">

            <div class="row justify-content-center">
                <div class="col-md-12 text-center home-tab-section-div">
                  <div class="row justify-content-center">
                    <div class="col-md-9 navtab-btn-div" data-aos="fade-up" data-aos-delay="100">
                        
                        <ul class="nav nav-tabs our-recent-project-ul" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                              <button class="active portfolio-catagory-tab-btn" id="home-tab" data-bs-toggle="tab" data-bs-target="#all" type="button" role="tab" aria-controls="home" aria-selected="true">All</button>
                            </li>
                            <li class="nav-item" role="presentation">
                              <button class="portfolio-catagory-tab-btn" id="profile-tab" data-bs-toggle="tab" data-bs-target="#website" type="button" role="tab" aria-controls="website" aria-selected="false">Website</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="portfolio-catagory-tab-btn" id="contact-tab" data-bs-toggle="tab" data-bs-target="#mobile" type="button" role="tab" aria-controls="mobile" aria-selected="false">Mobile</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="portfolio-catagory-tab-btn" id="contact-tab" data-bs-toggle="tab" data-bs-target="#graphic" type="button" role="tab" aria-controls="graphic" aria-selected="false">Graphic</button>
                            </li>
                          </ul>
                        
                        <!-- <script>

                            $('.portfolio-catagory-tab-btn').click(function() {
                            $('.portfolio-catagory-tab-btn').removeClass('activated-class'); 
                            $(this).addClass('activated-class'); 
                            })
                        
                        </script>
         -->
                    </div>
                    <div class="row justify-content-center">
                            <div class="col-md-12 text-center m-5">
                                <h2> <b> OUR RECENT PROJECTS </b> </h2>
                            </div>
                    </div>
                  </div>          
                  


                  <div class="tab-content" id="myTabContent">

                    <div class="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="home-tab">

                        <div class="row justify-content-center">

                            <div class="col-md-12">
                                <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                                    <div class="carousel-inner">
                                      <div class="carousel-item active">
                                        <div class="row justify-content-center">
                                            <div class="col-md-3 position-relative p-0 carousel-img-div">
                                                <div class="img-webname text-center">
                                                     <h5>Bomba Tacos</h5>
                                                </div>
                                                <img src="../assets/images/Bomba-Tacos-1-scaled.jpg" class="d-block w-100" alt="">
                                            </div>
                                            <div class="col-md-3 position-relative p-0 carousel-img-div home-carousel1-img2-div">
                                                <div class="img-webname text-center">
                                                    <h5>Blue Sky</h5>
                                                </div>
                                                <img src="../assets/images/Blue-Sky-scaled.jpg" class="d-block w-100" alt="">
                                            </div>
                                            <div class="col-md-3 position-relative p-0 carousel-img-div home-carousel1-img3-div">
                                                <div class="img-webname text-center">
                                                    <h5>Brady Towing</h5>
                                                </div>
                                                <img src="../assets/images/Brady-Towing-1-scaled.jpg" class="d-block w-100" alt="">
                                            </div>
                                        </div>
                                      </div>
                                      <div class="carousel-item">
                                        <div class="row justify-content-center">
                                            <div class="col-md-3 position-relative p-0 carousel-img-div">
                                                <div class="img-webname text-center">
                                                    <h5>Cow Cleaners</h5>
                                                </div>
                                                <img src="../assets/images/Cow-Cleaners-1-scaled.jpg" class="d-block w-100" alt="">
                                            </div>
                                            <div class="col-md-3 position-relative p-0 carousel-img-div home-carousel1-img2-div">
                                                <div class="img-webname text-center">
                                                    <h5>Cleaning Automaters</h5>
                                                </div>
                                                <img src="../assets/images/cleaning-automaters-scaled.jpg" class="d-block w-100" alt="">
                                            </div>
                                            <div class="col-md-3 position-relative p-0 carousel-img-div home-carousel1-img3-div">
                                                <div class="img-webname text-center">
                                                    <h5>True Clean</h5>
                                                </div>
                                                <img src="../assets/images/True-Clean-1-scaled.jpg" class="d-block w-100" alt="">
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                    <button class="home-carousel-prev-btn" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                        <i class="fa-solid fa-chevron-left"></i>
                                    </button>
                                    <button class="home-carousel-next-btn" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                        <i class="fa-solid fa-chevron-right"></i>
                                    </button>
                                  </div>
                            </div>

                        </div>
                    </div>


                    <div class="tab-pane fade" id="website" role="tabpanel" aria-labelledby="profile-tab">
                        
                        <!-- <div class="row justify-content-center">
                            <div class="col-md-5 text-center m-5">
                                <h4 class="text-uppercase"> <b> websites projects </b> </h4>
                            </div>
                        </div> -->
                        
                        <div class="row justify-content-center">
                            
                            <div class="col-md-12">
                                <div id="website-development" class="carousel slide" data-bs-ride="carousel">
                                    <div class="carousel-inner">
                                      <div class="carousel-item active">
                                        <div class="row justify-content-center">
                                            <div class="col-md-3 position-relative p-0 carousel-img-div">
                                                <div class="img-webname text-center">
                                                    <h5>Paladar</h5>
                                                </div>
                                                <img src="../assets/images/Paladar.jpg" class="d-block w-100" alt="">
                                            </div>
                                            <div class="col-md-3 position-relative p-0 carousel-img-div home-carousel1-img2-div">
                                                <div class="img-webname text-center">
                                                    <h5>Family First Cleaner</h5>
                                                </div>
                                                <img src="../assets/images/Family-First-Cleaners-1-1-scaled.jpg" class="d-block w-100" alt="">
                                            </div>
                                            <div class="col-md-3 position-relative p-0 carousel-img-div home-carousel1-img3-div">
                                                <div class="img-webname text-center">
                                                    <h5>Logixer</h5>
                                                </div>
                                                <img src="../assets/images/Logixer-1-scaled.jpg" class="d-block w-100" alt="">
                                            </div>
                                        </div>
                                      </div>
                                      <div class="carousel-item">
                                        <div class="row justify-content-center">
                                            <div class="col-md-3 position-relative p-0 carousel-img-div">
                                                <div class="img-webname text-center">
                                                    <h5>True Clean</h5>
                                                </div>
                                                <img src="../assets/images/True-Clean-1-scaled.jpg" class="d-block w-100" alt="">
                                            </div>
                                            <div class="col-md-3 position-relative p-0 carousel-img-div home-carousel1-img2-div">
                                                <div class="img-webname text-center">
                                                    <h5>Brady Towing</h5>
                                                </div>
                                                <img src="../assets/images/Brady-Towing-1-scaled.jpg" class="d-block w-100" alt="">
                                            </div>
                                            <div class="col-md-3 position-relative p-0 carousel-img-div home-carousel1-img3-div">
                                                <div class="img-webname text-center">
                                                    <h5>Classy Wear</h5>
                                                </div>
                                                <img src="../assets/images/classy-wear.jpg" class="d-block w-100" alt="">
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                    <button class="home-carousel-prev-btn" type="button" data-bs-target="#website-development" data-bs-slide="prev">
                                        <i class="fa-solid fa-chevron-left"></i>
                                    </button>
                                    <button class="home-carousel-next-btn" type="button" data-bs-target="#website-development" data-bs-slide="next">
                                        <i class="fa-solid fa-chevron-right"></i>
                                    </button>
                                  </div>
                            </div>

                        </div>
                    </div>

                    <div class="tab-pane fade" id="mobile" role="tabpanel" aria-labelledby="home-tab">
                        
                        <!-- <div class="row justify-content-center">
                            <div class="col-md-5 text-center m-5">
                                <h4 class="text-uppercase"> <b> mobile application projects</b> </h4>
                            </div>
                        </div> -->

                        <div class="row justify-content-center">
                            
                            <div class="col-md-12">
                                <div id="MobileProjects" class="carousel slide" data-bs-ride="carousel">
                                    <div class="carousel-inner">
                                      <div class="carousel-item active">
                                        <div class="row justify-content-center">
                                            <div class="col-md-3 position-relative p-0 carousel-img-div">
                                                <div class="img-webname text-center">
                                                    <h5>Bomba Tacus</h5>
                                                </div>
                                                <img src="../assets/images/Bomba-Tacos-1-scaled.jpg" class="d-block w-100" alt="">
                                            </div>
                                            <div class="col-md-3 position-relative p-0 carousel-img-div home-carousel1-img2-div">
                                                <div class="img-webname text-center">
                                                    <h5>Cow Cleaners</h5>
                                                </div>
                                                <img src="../assets/images/Cow-Cleaners-1-scaled.jpg" class="d-block w-100" alt="">
                                            </div>
                                            <div class="col-md-3 position-relative p-0 carousel-img-div home-carousel1-img3-div">
                                                <div class="img-webname text-center">
                                                    <h5>Family First Cleaner</h5>
                                                </div>
                                                <img src="../assets/images/Family-First-Cleaners-1-1-scaled.jpg" class="d-block w-100" alt="">
                                            </div>
                                        </div>
                                      </div>
                                      <div class="carousel-item">
                                        <div class="row justify-content-center">
                                            <div class="col-md-3 position-relative p-0 carousel-img-div">
                                                <div class="img-webname text-center">
                                                    <h5>Exchange</h5>
                                                    
                                                </div>
                                                <img src="../assets/images/Exchange-1-scaled.jpg" class="d-block w-100" alt="">
                                            </div>
                                            <div class="col-md-3 position-relative p-0 carousel-img-div home-carousel1-img2-div">
                                                <div class="img-webname text-center">
                                                    <h5>Logixers</h5>
                                                    
                                                </div>
                                                <img src="../assets/images/Logixer-1-scaled.jpg" class="d-block w-100" alt="">
                                            </div>
                                            <div class="col-md-3 position-relative p-0 carousel-img-div home-carousel1-img3-div">
                                                <div class="img-webname text-center">
                                                    <h5>Classy Wear</h5>
                                                    
                                                </div>
                                                <img src="../assets/images/classy-wear.jpg" class="d-block w-100" alt="">
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                    <button class="home-carousel-prev-btn" type="button" data-bs-target="#MobileProjects" data-bs-slide="prev">
                                        <i class="fa-solid fa-chevron-left"></i>
                                    </button>
                                    <button class="home-carousel-next-btn" type="button" data-bs-target="#MobileProjects" data-bs-slide="next">
                                        <i class="fa-solid fa-chevron-right"></i>
                                    </button>
                                  </div>
                            </div>

                        </div>
                    </div>

                    <div class="tab-pane fade" id="graphic" role="tabpanel" aria-labelledby="profile-tab">

                        <!-- <div class="row justify-content-center">
                            <div class="col-md-5 text-center m-5">
                                <h4 class="text-uppercase"> <b> graphic design projects </b> </h4>
                            </div>
                        </div> -->

                        <div class="row justify-content-center">
                            
                            <div class="col-md-12">
                                <div id="GraphicTab" class="carousel slide" data-bs-ride="carousel">
                                    <div class="carousel-inner">
                                      <div class="carousel-item active">
                                        <div class="row justify-content-center">
                                            <div class="col-md-3 position-relative p-0 carousel-img-div">
                                                <div class="img-webname text-center">
                                                    <h5>Blue Sky</h5>
                                                </div>
                                                <img src="../assets/images/Blue-Sky-scaled.jpg" class="d-block w-100" alt="">
                                            </div>
                                            <div class="col-md-3 position-relative p-0 carousel-img-div home-carousel1-img2-div">
                                                <div class="img-webname text-center">
                                                    <h5>Cleaning Automaters</h5>
                                                </div>
                                                <img src="../assets/images/cleaning-automaters-scaled.jpg" class="d-block w-100" alt="">
                                            </div>
                                            <div class="col-md-3 position-relative p-0 carousel-img-div home-carousel1-img3-div">
                                                <div class="img-webname text-center">
                                                    <h5>G.I Party</h5>
                                                </div>
                                                <img src="../assets/images/G.I-Party-scaled.jpg" class="d-block w-100" alt="">
                                            </div>
                                        </div>
                                      </div>
                                      <div class="carousel-item">
                                        <div class="row justify-content-center">
                                            <div class="col-md-3 position-relative p-0 carousel-img-div">
                                                <div class="img-webname text-center">
                                                    <h5>Mop-A-Lot</h5>
                                                </div>
                                                <img src="../assets/images/Mop-A-Lot-scaled.jpg" class="d-block w-100" alt="">
                                            </div>
                                            <div class="col-md-3 position-relative p-0 carousel-img-div home-carousel1-img2-div">
                                                <div class="img-webname text-center">
                                                    <h5>Sparkle Easy</h5>
                                                </div>
                                                <img src="../assets/images/Sparkle-Easy-scaled.jpg" class="d-block w-100" alt="">
                                            </div>
                                            <div class="col-md-3 position-relative p-0 carousel-img-div home-carousel1-img3-div">
                                                <div class="img-webname text-center">
                                                    <h5>Sparkle Easy</h5>
                                                </div>
                                                <img src="../assets/images/Sparkle-Easy-scaled.jpg" class="d-block w-100" alt="">
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                    <button class="home-carousel-prev-btn" type="button" data-bs-target="#GraphicTab" data-bs-slide="prev">
                                        <i class="fa-solid fa-chevron-left"></i>
                                    </button>
                                    <button class="home-carousel-next-btn" type="button" data-bs-target="#GraphicTab" data-bs-slide="next">
                                        <i class="fa-solid fa-chevron-right"></i>
                                    </button>
                                  </div>
                            </div>

                        </div>
                    </div>
                    
                  </div>
                </div>
            </div>
            
        </div>
    </div>

    <!-- Lates Blogs Section -->
    <!-- <div class="container-fluid latest-blogs">
        <div class="container choose-us-line-seprator">
            <div class="row justify-content-center">
                <div class="col-md-4 text-center" data-aos="fade-up" data-aos-delay="200">
                    <h4> <b>LATEST BLOGS</b> </h4>     
                </div>
            </div>
        <div class="row justify-content-center">
            <div class="col-md-8 blog-img-div">
                <div class="row justify-content-center">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12 p-1 position-relative" data-aos="fade-right" data-aos-delay="200">
                                <img src="../assets/images/latestblog-img1.png" alt="">
                                <div class="img1-div">
                                    <h5>Lorem ipsum dolor  phasellus consectet</h5>
                                    <p>
                                        Lorem ipsum dolor  phasellus consectet sit amet.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 p-1 position-relative" data-aos="fade-left" data-aos-delay="300">
                                <img src="../assets/images/latestblog-img2.png" alt="">
                                <div class="img2-div">
                                    <h5>Lorem ipsum dolor</h5>
                                    <p>
                                        Lorem ipsum dolor  sit amet.
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-6 p-1 position-relative" data-aos="fade-up" data-aos-delay="350">
                                <img src="../assets/images/latestblog-img3.png" alt="">
                                <div class="img3-div">
                                    <h5>Lorem ipsum dolor</h5>
                                    <p>
                                        Lorem ipsum dolor  sit amet.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 p-1 position-relative" data-aos="fade-left" data-aos-delay="400">
                        <img src="../assets/images/latestblog-img4.png" alt="">
                        <div class="img4-div">
                            <h5>Lorem ipsum dolor  phasellus consectet</h5>
                            <p>
                                Lorem ipsum dolor awco wooqww qwerokhf  qwoo phasellus consectet sit amet.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-12 p-1 position-relative" data-aos="fade-right" data-aos-delay="450">
                                <img src="../assets/images/latestblog-img2.png" alt="">
                                <div class="img5-div">
                                    <h5>Lorem ipsum dolor  </h5>
                                    <p>
                                        Lorem ipsum consectet sit amet.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 p-1 position-relative" data-aos="fade-left" data-aos-delay="500">
                                <img src="../assets/images/latestblog-img6.png" alt="">
                                <div class="img6-div">
                                    <h5>Lorem ipsum dolor </h5>
                                    <p>
                                        Lorem ipsum dolor ok sit amet.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-8 m-4">
                        <div class="row text-center">
                           <a href="blogs.html"> <button class="more-blogs-btn">More Blogs</button> </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div> -->

    <!-- WHAT OUR CLIENTS SAY ABOUT US Section -->
    <!-- <div class="container-fluid what-our-client-say">
        <div class="container choose-us-line-seprator">
            <div class="row justify-content-center">
                <div class="col-md-6 text-center" data-aos="fade-up" data-aos-delay="200">
                    <h4> <b> WHAT OUR CLIENTS SAY ABOUT US </b> </h4>
                    <p>
                        Lorem ipsum dolor  phasellus consectet sit amet,consectetur adipi elit.Non leo malesuada est Vitae phasellus fringilla in.phasellus Vitae faucibus .
                    </p>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-12 profile-menu-div-line">
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-12 p-0">
                    <div class="row justify-content-center p-4 profile-img-row">
                        
                            <div class="profile-img-div" data-aos="fade-left" data-aos-delay="200">
                                <img src="../assets/images/profile-img1.jpg" alt="">
                                <div class="profile-info">
                                    <h6>John David</h6>
                                    <p>Senior Developer</p>
                                    <p>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                    </p>
                                </div>    
                            </div>
                            <div class="profile-img-div" data-aos="fade-left" data-aos-delay="250">
                                <img src="../assets/images/profile-img2.jpeg" alt="">
                                <div class="profile-info">
                                    <h6>Brus John</h6>
                                    <p>Junior Developer</p>
                                    <p>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                    </p>
                                </div>    
                            </div>
                            <div class="profile-img-div" data-aos="fade-left" data-aos-delay="300">
                                <img src="../assets/images/profile-img3.jpg" alt="">
                                <div class="profile-info">
                                    <h6>Alaxa David Broks</h6>
                                    <p>App Developer</p>
                                    <p>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                    </p>
                                </div>    
                            </div>
                            <div class="profile-img-div" data-aos="fade-left" data-aos-delay="350">
                                <img src="../assets/images/profile-img4.jpg" alt="">
                                <div class="profile-info">
                                    <h6>Adam Smith</h6>
                                    <p>Senior Graphic Developer</p>
                                    <p>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                    </p>
                                </div>    
                            </div>
                            <div class="profile-img-div" data-aos="fade-left" data-aos-delay="400">
                                <img src="../assets/images/profile-img5.jpg" alt="">
                                <div class="profile-info">
                                    <h6>Talor Swift</h6>
                                    <p>Junior Graphic Developer</p>
                                    <p>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                    </p>
                                </div>    
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <!-- Our Partners Section -->
    <div class="container-fluid our-partner-div">
        <div class="container choose-us-line-seprator">
            <div class="row justify-content-center">
                <div class="col-md-12 text-title text-center" data-aos="fade-up" data-aos-delay="100">
                    <h1> <b> OUR PARTNERS </b> </h1>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-12 home-2nd-carousel-div" data-aos="fade-up" data-aos-delay="200">
                    <div id="carouselExample2Controls" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                          <div class="carousel-item active">
                            <div class="row justify-content-center">
                                <div class="col-md-2 position-relative p-4 m-3 carousel2-img" data-aos="fade-left" data-aos-delay="300">
                                    <img src="../assets/images/PNG-01-1.png" class="d-block w-100" alt="">
                                </div>
                                <div class="col-md-2 position-relative p-4 m-3 carousel2-img home-carousel2-img2-div" data-aos="fade-left" data-aos-delay="400">
                                    <img src="../assets/images/Dust-Bunny-Solution-Logo-PNG-01-1.png" class="d-block w-100" alt="">
                                </div>
                                <div class="col-md-2 position-relative p-4 m-3 carousel2-img home-carousel2-img2-div" data-aos="fade-left" data-aos-delay="500">
                                    <img src="../assets/images/Group-1239.png" class="d-block w-100" alt="">
                                </div>
                                <div class="col-md-2 position-relative p-4 m-3 carousel2-img home-carousel2-img2-div" data-aos="fade-left" data-aos-delay="600">
                                    <img src="../assets/images/PNG-1.png" class="d-block w-100" alt="">
                                </div>
                            </div>
                          </div>
                          <div class="carousel-item">
                            <div class="row justify-content-center">
                                <div class="col-md-2 position-relative p-4 m-3 carousel2-img home-carousel2-img2-div">
                                    <img src="../assets/images/Group-1238.png" class="d-block w-100" alt="">
                                </div>
                                <div class="col-md-2 position-relative p-4 m-3  carousel2-img">
                                    <img src="../assets/images/Group-1236.png" class="d-block w-100" alt="">
                                </div>
                                <div class="col-md-2 position-relative p-4 m-3 carousel2-img home-carousel2-img2-div">
                                    <img src="../assets/images/PNG-01-2.png" class="d-block w-100" alt="">
                                </div>
                                <div class="col-md-2 position-relative p-4 m-3 carousel2-img home-carousel2-img2-div">
                                    <img src="../assets/images/Bradys-Towing-Service-logo-01-1.png" class="d-block w-100" alt="">
                                </div>
                            </div>
                          </div>
                        </div>
                        <button class="home-carousel2-prev-btn" type="button" data-bs-target="#carouselExample2Controls" data-bs-slide="prev">
                            <img src="../assets/images/left-prev-arrow.png" alt="">
                        </button>
                        <button class="home-carousel2-next-btn" type="button" data-bs-target="#carouselExample2Controls" data-bs-slide="next">
                            <img src="../assets/images/right-next-arrow.png" alt="">
                        </button>
                      </div>
                </div>
            </div>
        </div>
    </div>

    <!-- OUR CONTACT & DROP YOUR MASSAGE HERE SECTION -->
    <div class="container-fluid contact-dropmsg-section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-12 contact-info-bottom p-5">
                    <div class="row">

                        <div class="col-md-4 contact-info-div">
                            <h4> CONTACT INFORMATION</h4>
                            <div class="col-md-12 contact-info-bottom-box">

                                <div class="col-md-12 m-2 contact-box-div">
                                    <div class="row m-0">
                                        <div class="col-md-2 contact-icon-div">
                                            <i class="fa-solid fa-phone m-2"></i>
                                        </div>
                                        <div class="col-md-9 contact-icon-text-div">
                                            <a href="callto:+923367944092">+923367944092</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12 m-2 contact-box-div">
                                    <div class="row m-0">
                                        <div class="col-md-2 contact-icon-div">
                                            <i class="fa-solid fa-envelope m-2"></i>
                                        </div>
                                        <div class="col-md-9 contact-icon-text-div">
                                            <a href="mailto:nfo@logixers.com">info@logixers.com</a> 
                                        </div>
                                    </div>
                                </div>
            
                                <div class="col-md-12 m-2 contact-box-div">
                                    <div class="row m-0">
                                        <div class="col-md-2 contact-icon-div">
                                            <i class="fa-solid fa-location-dot m-2"></i>
                                        </div>
                                        <div class="col-md-9 contact-icon-text-div">
                                            <span>Kohinoor City</span> 
                                        </div>
                                    </div>
                                </div> 

                                <div class="col-md-12 social-icon-infobox p-2">
                                    <a target="blank" href="https://www.facebook.com/logixer"><i class="fa-brands fa-facebook-f"></i></a>
                                    <a target="blank" href="https://instagram.com/logixers?igshid=MzRlODBiNWFlZA=="><i class="fa-brands fa-instagram"></i></a>
                                    <a target="blank" href="https://www.linkedin.com/in/logixers"><i class="fa-brands fa-linkedin"></i></a>
                                    <a target="blank" href="https://twitter.com/logixers?t=tFNP7N5qUrX1UmG1n14_2w&s=09"><i class="fa-brands fa-twitter"></i></a>
                                </div>                
                            </div>
                        </div>
 
                        <div class="col-md-8 drop-your-msg-div">
                            <h4>DROP YOUR MESSAGE</h4>
                            <div class="col-md-12 drop-msg-bottom-box">                        
                                <form @submit.prevent="contact">                       
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-floating mb-3">
                                            <input type="text" v-model="form.fname" class="form-control" id="floatingInput" placeholder="name@example.com">
                                            <label for="floatingInput">First Name</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-floating mb-3">
                                            <input type="text" v-model="form.lname" class="form-control" id="floatingInput" placeholder="name@example.com">
                                            <label for="floatingInput">Last Name</label>
                                        </div>
                                    </div>
                                </div>  
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-floating mb-3">
                                            <input type="email" v-model="form.email" class="form-control" id="floatingInput" placeholder="name@example.com">
                                            <label for="floatingInput">Email address</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-floating mb-3">
                                            <input type="number" v-model="form.phone" class="form-control" id="floatingInput" placeholder="name@example.com">
                                            <label for="floatingInput">Phone*</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-floating mb-3">
                                            <input type="text" v-model="form.subject" class="form-control" id="floatingInput" placeholder="name@example.com">
                                            <label for="floatingInput">Subject</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-floating mb-3">
                                            <textarea class="form-control" v-model="form.description" id="floatingInput" placeholder="name@example.com"></textarea>
                                            <label for="floatingInput">Tell Us A Little About Your Property</label>
                                        </div>
                                    </div>
                                </div>        
                                <div class="row justify-content-end">
                                    <div class="col-md-6 drop-msg-btn">
                                        <button class="drop-msg-btn">SUBMIT</button>
                                    </div>
                                </div>
                                </form>          
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- Bootstrap 5 JS link -->
    <!-- <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>

    <script>
      var loader = $('.preloader');
      window.addEventListener('load',function(){
        loader.css('display','none');
      })
    </script> -->



</template>

<script>
import apiClient from '@/apiService.js';
import {reactive} from 'vue';
import Swal from 'sweetalert2';
export default {
name: 'IndexComp',
setup(){
    const form = reactive({
        fname:'',
        lname:'',
        email:'',
        phone:'',
        subject:'',
        description:'',
    });
    const contact = () =>{
        apiClient.post('api/contact',form).then(res=>{
            if(res.data.success == true){
                    Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: res.data.message,
                    });
                }
        });
    } 
    return{
        contact,
        form
    }
  }
}
</script>
