<template>


<!-- GOOD DESIGN BRINGS GOOD BUSINESS --- Section -->
<div class="container-fluid graphic-front-content-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 good-design-content-text text-center" data-aos="fade-up" data-aos-delay="400">
                <h1> EYE-CATCHING DESIGNS GRAB THE ATTENTION </h1>
                <p> Great Graphic Design Will Always Be Behind A Well Executed Business Plan, Because High-Impact Visuals Are Mandatory For Every Successful Business.</p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-12 graphic-front-content-img-div">
                <img src="../assets/images/Graphic-front-content-img.png" alt="" data-aos="zoom-in" data-aos-delay="400">
                <div class="col-md-12 text-center" data-aos="fade-up" data-aos-delay="800">
                    <h4> GRAPHIC DESIGN SERVICE <br> WE PROVIDE</h4>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- GRAPHIC DESIGN SERVICES SECTION -->
<div class="container-fluid graphic-design-service-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 graphic-design-service-grid-div">
                <div class="row">

                    <div class="col-lg-3 col-md-4 services-img service-box" data-aos="fade-down" data-aos-delay="100">
                        <div class="col-md-12 text-center services-img">
                            <img src="../assets/images/ux-design-1.png" alt="">
                        </div>
                        <div class="col-md-12 text-center p-2">
                            <h6>UI/UX DESIGN</h6>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 text-center services-img service-box" data-aos="fade-up" data-aos-delay="200">
                        <div class="col-md-12 text-center services-img">
                            <img src="../assets/images/ux-design-2.png" alt="">
                        </div>
                        <div class="col-md-12 text-center p-2">
                            <h6>BRANDING DESIGN</h6>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 text-center services-img service-box" data-aos="fade-down" data-aos-delay="300">
                        <div class="col-md-12 text-center services-img">
                            <img src="../assets/images/ux-design-3.png" alt="">
                        </div>
                        <div class="col-md-12 text-center p-2">
                            <h6>LOGO DESIGN</h6>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 text-center services-img service-box" data-aos="fade-up" data-aos-delay="400">
                        <div class="col-md-12 text-center services-img">
                            <img src="../assets/images/ux-design-4.png" alt="">
                        </div>
                        <div class="col-md-12 text-center p-2">
                            <h6>SOCIAL MEDIA GRAPHICS</h6>
                        </div>
                    </div>
             
                    <div class="col-lg-3 col-md-4 text-center services-img service-box" data-aos="fade-down" data-aos-delay="500">
                        <div class="col-md-12 text-center services-img">
                            <img src="../assets/images/ux-design-5.png" alt="">
                        </div>
                        <div class="col-md-12 text-center p-2">
                            <h6>PRINTING DESIGN</h6>
                        </div>                        
                    </div>
                    <div class="col-lg-3 col-md-4 text-center services-img service-box" data-aos="fade-up" data-aos-delay="600">
                        <div class="col-md-12 text-center services-img">
                            <img src="../assets/images/ux-design-6.png" alt="">
                        </div>
                        <div class="col-md-12 text-center p-2">
                            <h6>PACKING DESIGN</h6>
                        </div>                        
                    </div>
                    <div class="col-lg-3 col-md-4 text-center services-img service-box" data-aos="fade-down" data-aos-delay="700">
                        <div class="col-md-12 text-center services-img">
                            <img src="../assets/images/ux-design-7.png" alt="">
                        </div>
                        <div class="col-md-12 text-center p-2">
                            <h6>INFOGRAPHICS DESIGN</h6>
                        </div>                        
                    </div>
                    <div class="col-lg-3 col-md-4 text-center services-img service-box" data-aos="fade-up" data-aos-delay="800">
                        <div class="col-md-12 text-center services-img">
                            <img src="../assets/images/ux-design-8.png" alt="">
                        </div>
                        <div class="col-md-12 text-center p-2">
                            <h6>OTHERS</h6>
                        </div>                        
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">

                <!-- <button class="btn btn-outline-success" type="submit">Get Started</button> -->
                <button type="button" class="col-3 btn request-consulation" data-bs-toggle="modal" data-bs-target="#graphicmodalbtn" data-bs-whatever="@getbootstrap">
                    Request A Free Consulaion
                </button>

                <div class="modal fade" id="graphicmodalbtn" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Get Started & REQUEST A QUATE FORM</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                    <div class="col-md-12 drop-your-msg-div text-center">
                        <h4><b>REQUEST A QUATE FORM</b></h4>
                        <div class="col-md-12 drop-msg-bottom-box">                        
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-floating mb-3">
                                        <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com">
                                        <label for="floatingInput">First Name</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-3">
                                        <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com">
                                        <label for="floatingInput">Last Name</label>
                                    </div>
                                </div>
                            </div>  
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-floating mb-3">
                                        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
                                        <label for="floatingInput">Email address</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-floating mb-3">
                                        <input type="number" class="form-control" id="floatingInput" placeholder="name@example.com">
                                        <label for="floatingInput">Phone*</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-3">
                                        <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com">
                                        <label for="floatingInput">Subject</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-floating mb-3">
                                        <textarea cols="5" class="form-control" id="floatingInput" placeholder="name@example.com"></textarea>
                                        <label for="floatingInput">Tell Us A Little About Your Property</label>
                                    </div>
                                </div>
                            </div>        
                            <div class="row justify-content-end">
                                <div class="col-md-4">
                                    <button class="modal-submit-btn">SUBMIT</button>
                                </div>
                            </div>          
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                </div>

            </div>
        </div>
    </div>
</div>


<!-- Want to speak with Our Creative Director?CONTACT US!-------- Section-->
<div class="container-fluid blue-box-section" data-aos="fade-up" data-aos-delay="100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 p-5 blue-box">
                <div class="row justify-content-center">
                    <div class="col-md-8 position-relative pen-nib-div">
                        <h2>Want to speak with Our Creative Director ? <br> CONTACT US!</h2>
                        <img src="../assets/images/pen-nib.png" alt="">
                    </div>
                    <div class="col-md-4 blue-box-contact-box">
                        <div class="col-md-12 p-2">
                            <p>CALL-US :</p>
                            <i class="fa-solid fa-phone"></i>
                            <a href="tel:+923367944092">+923367944092</a>
                        </div>
    
                        <div class="col-md-12 p-2">
                            <p>E-MAIL :</p>
                            <i class="fa-solid fa-envelope"></i>
                            <a href="mailto:info@logixers.com">info@logixers.com</a>
                        </div>
                    </div>                            
                </div>
            </div>
        </div>
    </div>
</div>


<!-- paragraph section -->
<div class="container-fluid paragraph-section" data-aos="fade-up" data-aos-delay="200">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 p-5 paragraph-boxes">
                <div class="row justify-content-center">
                    <div class="col-lg-6 col-md-12 p-3">
                        <h4 class="text-uppercase"> LET OUR GRAPHIC DESIGNERS REPRESENT YOU VISUALLY IN HIGH-IMPACT CREATIVE DESIGNS.</h4>
                        <p>We consider the psychological outcomes that your branding could have on outlook; we work to advertise your brands visually. Our experts create highly satisfying and simple designs that visually attract your customers to your posts and provokes them to get the message of your brand where as a poorly created design could affect the engagement of your audience.</p>
                    </div>
                    <div class="col-lg-6 col-md-12 p-3">
                        <h4 class="text-uppercase"> YOUR CORPORATE IDENTITY DESIGN IS OUR RESPONSIBILITY.</h4>
                        <p>
                            An Appropriate Use Of Typography, Content Layouts, And Suitable Imagery Are Things That Matter  These Are The Groundwork Of The Many Features That Our Graphic Designers Consider While Designing Your Brand’s Visual Representation. Our Designers Mingle The Innovative Techniques For Your Brand’s Finest Corporate Identity.  Our Work Will Represent Your Brand Globally Be It In The Uk, USA, Canada, Or Any Other Leading Country Of The World. The Visuals That Our Company Offers Grow Your Brands And Optimizs The Marketing Efforts.                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
        
    
</template>
<script>
export default {
    name:'GraphicDesigning'
}
</script>