<template>



<!-- Footer Section -->
<div class="container-fluid footer">
<div class="container footer-for-color">

    <div class="row m-0 justify-content-center">
        <div class="col-md-12 p-0 footer-for-ipads">
            <div class="row m-0">
                <div class="col-md-12 footer-input p-4">
                    <form @submit.prevent="subscribe">
                        <input type="text" v-model="form.email" placeholder="Enter Your E-Mail" class="col-md-4"> 
                        <button>Subscribe Now!</button>
                    </form>
                </div>
            </div>
            <div class="row m-0 justify-content-center">
                <div class="col-md-12 footer-links-section">
                    <div class="row m-0">
                        <div class="col-md-4 footer-top-page-section">
                            <h6>TOP PAGES</h6>
                            <div class="line"></div>
                            <router-link to="/"> Home </router-link>
                            <router-link to="/about"> About </router-link>
                            <router-link to="/services"> Services </router-link>
                            <router-link to="/career"> Career </router-link>
                            <router-link to="/contact"> Contact </router-link>
                            <router-link to="/portfolio"> Portfolio </router-link>
                            <!-- <router-link to="/blogs"> Blogs </router-link><br> -->
                            <router-link to="/faq"> FAQ'S </router-link>
                        </div>
                        <div class="col-md-4 footer-services-section">
                            <h6>SERVICES</h6>
                            <div class="line"></div>
                            <router-link to="/websitedevelopment"> Website Development </router-link>

                            <!-- <a href="mobile-development.html" @click="noneDisplay()">Mobile App Development</a><br> -->
                            <router-link to="/graphicdesigning"> Graphic Designing </router-link>
                            <router-link to="/seo"> SEO </router-link>
                            <router-link to="/digitalmarketing"> Digital Marketing </router-link>
                            <router-link to="/ecommerce"> E-Commerce Solution </router-link>
                        </div>
                        <div class="col-md-4 footer-get-in-touch-section position-relative">
                            <h6>GET IN TOUCH</h6>
                            <div class="line"></div>
                            <div class="social-icon-section">
                                <a target="blank" href="https://instagram.com/logixers?igshid=MzRlODBiNWFlZA=="><img src="../assets/images/instagram-img.png" alt=""></a>
                                <a target="blank" href="https://www.linkedin.com/in/logixers"><img src="../assets/images/linkedin-img.png" alt=""></a>
                                <a target="blank" href="https://twitter.com/logixers?t=tFNP7N5qUrX1UmG1n14_2w&s=09"><img src="../assets/images/twitter-img.png" alt=""></a>
                                <a target="blank" href="https://www.facebook.com/logixer"><img src="../assets/images/facebook-img.png" alt=""></a>

                            </div>
                            
                            <a href="#" class="slide-up-div" data-aos="fade-up">
                                <i class="fas fa-long-arrow-alt-up"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="row m-0 justify-content-center footer-end">
        <div class="col-lg-8 col-md-12 text-center" id="scrolup">
            <div class="row m-0">
                <div class="col-md-4 footer-logo">
                    <router-link to="/"> <img src="../assets/images/logixerWhite-logo.png" alt=""></router-link>
                </div>
                <div class="col-lg-8 col-md-8 copyright-line">
                    <p>Copyright ©2020 <router-link to="/"> LOGIXERS</router-link>. All Rights Reserved</p>
                </div>
            </div>
        </div>
    </div>

</div>
</div>




</template>

<script>
import $ from 'jquery';
import apiClient from '@/apiService.js';
import {reactive} from 'vue';
import Swal from 'sweetalert2';

    if(window.scrollY >= 11){
        $('.slide-up-div').css('opacity','1!important')
        console.log('display-block');
    }
export default {
    name: 'FooterWeb',
setup(){

const noneDisplay =() =>{

    if (window.scrollY > 8){
        $('.slide-up-div').css('opacity','0')
        console.log('display-none');
    }
}
const form = reactive({
        email:''
    });
const subscribe = () => {
    apiClient.post('api/email_subscribe',form).then(res => {
        if(res.data.success == true){
                Swal.fire({
                icon: 'success',
                title: 'Success',
                text: res.data.message,
                });
        }
    });
}
    return{
        noneDisplay,
        subscribe,
        form
    }
}


    
}


</script>


<style>
ol{
    padding: 0;
}
</style>